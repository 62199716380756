import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux";

import { InfoPanel } from "../../../InfoPanel/InfoPanel";
import ButtonCustom from "../../../ButtonCustom/ButtonCustom";
import EmptyListWithImage from "../../../EmptyListWithImage/EmptyListWithImage";
import JobsListingEffect from "../../../effects/JobsListingEffect/JobsListingEffect";

import Image from "../../../../assets/svg/discover_no_people.svg";

const DiscoverPeople = ({
  networks = [],
  showLoader = false,
  activePanelIndex,
  handleView,
  handleMessage,
  handleClose,
  handleFavorite,
  selectedUserData = false,
  userId = null,
  currentPage = 1,
  totalPages = 1,
  fetchMoreData = () => {},
}) => {
  const { favoriteUsers = [] } = useSelector((store) => store.networkStore);

  return (
    <>
      <div className="people-container">
        {showLoader ? (
          <JobsListingEffect count={8} />
        ) : networks.length > 0 ? (
          <InfiniteScroll
            dataLength={networks.length}
            next={fetchMoreData}
            hasMore={
              currentPage !== totalPages &&
              totalPages !== 0 &&
              networks.length !== 0
            }
            loader={
              <div style={{ margin: "20px 0 0 0" }}>
                <JobsListingEffect count={3} />
              </div>
            }
            style={{ overflow: "none" }}
          >
            {networks.map((item, index) => {
              let subTitle = [];
              if (item?.major) subTitle.push(item.major);
              if (item?.graduation_year) subTitle.push(item.graduation_year);
              return (
                <InfoPanel
                  variant="discover-network"
                  key={index}
                  index={index}
                  avatar={item.user_profile}
                  userAvatar={true}
                  title={item.full_name}
                  subTitle={subTitle}
                  tags={item?.user_types || []}
                  isFavorites={favoriteUsers.includes(item.id) ? true : false}
                  onClick={(e) => handleFavorite(e, item.id)}
                  onPanelClick={(e) =>
                    activePanelIndex === item.id
                      ? handleClose(e)
                      : handleView(e, item.id)
                  }
                  intro={
                    item.country_image !== "" && (
                      <>
                        <div className="c-flag">
                          <img
                            src={item.country_image}
                            height="15px"
                            width="22px"
                            alt={`Flag of ${item.country_of_origin}`}
                          />
                        </div>
                        <div style={{ marginLeft: "8px" }}>
                          {item.country_of_origin}
                        </div>
                      </>
                    )
                  }
                  isActive={
                    activePanelIndex === item.id ||
                    (selectedUserData &&
                      selectedUserData === true &&
                      item.id === userId)
                  }
                  action={
                    <ButtonCustom
                      height={30}
                      width={100}
                      color="secondary"
                      className={
                        activePanelIndex === item.id ? "focus-white" : ""
                      }
                      onClick={(e) => {
                        handleMessage(e, item);
                      }}
                      aria-label={`Message ${item.full_name}`}
                    >
                      Message
                    </ButtonCustom>
                  }
                  marginTop="0px"
                />
              );
            })}
          </InfiniteScroll>
        ) : (
          <EmptyListWithImage
            Image={Image}
            title={"Looks like there are no people in this search."}
            subTitle={
              "Try removing some filters to expand your search and discover other people!"
            }
            containerClassname={"empty-discover-people"}
          />
        )}
      </div>
    </>
  );
};

export default DiscoverPeople;
