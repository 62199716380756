import axiosInstance from "../config/axiosInterceptors";
import { headers_with_token } from "../helper/helper";

export const getDashboardUserPostsAPI = (page, pageSize = 10, options = {}) => {
  const queryString = Object.keys(options)
    .map((option) => `${option}=${options[option]}`)
    .join("&");
  let link = `user_posts?page=${page}&per_page=${pageSize}`;
  link = Object.keys(options).length > 0 ? `${link}&${queryString}` : link;
  return axiosInstance.get(link, headers_with_token()).then((res) => {
    return res.data;
  });
};

export const getSavedPostsListAPI = (page = 1) => {
  return axiosInstance
    .get(`user_posts/saved_posts_list?page=${page}`, headers_with_token())
    .then((res) => {
      return res.data;
    });
};

export const markOrientationAPI = () => {
  return axiosInstance
    .get(`users/orientation_viewed`, headers_with_token())
    .then((res) => {
      return res.data;
    });
};

export const savedPostAPI = (postID) => {
  return axiosInstance
    .post(
      "user_posts/save_post",
      { user_post_id: postID },
      headers_with_token()
    )
    .then((res) => {
      return res.data;
    });
};

export const updateUserInfo = async (requestData) => {
  return axiosInstance
    .post("/users/update_user_info", requestData, headers_with_token())
    .then((res) => {
      return res.data;
    });
};

export const hidePostAPI = (postID) => {
  return axiosInstance
    .post(
      "user_posts/hide_post",
      { user_post_id: postID },
      headers_with_token()
    )
    .then((res) => {
      return res.data;
    });
};

export const sendGetAppCodeSMS = (code, phone) => {
  const mobileNo = (code + phone).replace(/\D/g, "");
  return axiosInstance
    .get(
      `users/send_app_links?user_mobile_number=${mobileNo}`,
      headers_with_token()
    )
    .then((res) => {
      return res.data;
    });
};

export const inviteFriendAPI = (payload = null) => {
  return axiosInstance
    .post(
      "users/invite_friend",
      payload,
      headers_with_token({ formData: true })
    )
    .then((res) => {
      return res.data;
    });
};
