import React from "react";
import { Avatar } from "@mui/material";

import CompanyRightStyles from "./CompanyRightStyles";
import InitialAvatar from "../../../InitialAvatar/InitialAvatar";

import facebookImg from "../../../../assets/SocialIcon/updatedIcons/facebook.svg";
import googleImg from "../../../../assets/SocialIcon/updatedIcons/google.svg";
import LinkdinImg from "../../../../assets/SocialIcon/updatedIcons/linkedin.svg";
import twitterImg from "../../../../assets/SocialIcon/updatedIcons/twitter.svg";
import wikipediaImg from "../../../../assets/SocialIcon/updatedIcons/wikipedia.svg";
import crunchImg from "../../../../assets/SocialIcon/updatedIcons/cruchbase.svg";
import glassDoorImg from "../../../../assets/SocialIcon/updatedIcons/glassdoor.svg";
import angelistImg from "../../../../assets/SocialIcon/updatedIcons/abgelist.svg";

const CompanyRight = ({ companyData = {} }) => {
  console.log(companyData); // To be removed
  const renderServices = (item, i, total_length) => {
    return (
      <li key={i} className="text-grey">
        {item}
        {total_length >= 0 && i >= 0 && i < total_length ? "," : ""}
      </li>
    );
  };

  return (
    <CompanyRightStyles>
      <div className="h-information">
        {companyData && (
          <React.Fragment>
            <div className="h-information--logo">
              {companyData.company_logo ? (
                <Avatar
                  alt={
                    companyData.employer_name
                      ? `${companyData.employer_name}'s logo`
                      : "default logo"
                  }
                  src={companyData.company_logo}
                  className="company-logo"
                />
              ) : (
                <InitialAvatar
                  variant="square"
                  title={companyData.employer_name}
                  initialCount={1}
                  index={1}
                />
              )}
            </div>
            <div className="title-primary">
              {companyData?.employer_name ?? ""}
            </div>
            <div className="">
              <div className="services mb-5">
                <ul>
                  {companyData?.domain_search_response?.details?.industries &&
                  companyData.domain_search_response.details.industries
                    .length ? (
                    <>
                      {companyData.domain_search_response.details.industries.map(
                        (industry, i) =>
                          renderServices(
                            industry.name,
                            i,
                            companyData.domain_search_response.details
                              .industries.length
                          )
                      )}
                    </>
                  ) : (
                    <>
                      {companyData &&
                        (companyData.industry ?? "")
                          .split(",")
                          .map((industry, i) => renderServices(industry, i))}
                    </>
                  )}
                </ul>
              </div>
              <div className="text-grey">
                {companyData?.domain_search_response?.employees ? (
                  companyData.domain_search_response.employees
                ) : (
                  <>{companyData && companyData.employees}</>
                )}
              </div>
            </div>
            <div className="header-primary">
              {companyData?.domain_search_response?.bio ? (
                companyData.domain_search_response.bio
              ) : (
                <>{companyData && companyData.company_description}</>
              )}
            </div>
            <div className="text-center">
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={
                  "https://" +
                  (companyData?.domain_search_response?.website
                    ? companyData.domain_search_response.website
                    : companyData && companyData.website
                    ? companyData.website
                    : "#")
                }
                className="nlink-secondary"
                aria-label="company website"
              >
                {companyData?.domain_search_response?.website ? (
                  companyData.domain_search_response.website
                ) : (
                  <>
                    {companyData && companyData.website
                      ? companyData.website
                      : ""}
                  </>
                )}
              </a>
            </div>

            <div className="social-icons">
              <ul>
                {companyData?.domain_search_response?.linkedin ? (
                  <li>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href={companyData.domain_search_response.linkedin}
                      style={{ textDecoration: "none" }}
                      aria-label="LinkedIn"
                    >
                      <Avatar src={LinkdinImg} alt="LinkedIn" />
                      <span>LinkedIn</span>
                    </a>
                  </li>
                ) : (
                  <>
                    {companyData.linkedin_link && (
                      <li>
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href={companyData.linkedin_link}
                          style={{ textDecoration: "none" }}
                          aria-label="LinkedIn"
                        >
                          <Avatar src={LinkdinImg} alt="LinkedIn" />
                          <span>LinkedIn</span>
                        </a>
                      </li>
                    )}
                  </>
                )}

                {companyData?.domain_search_response?.twitter ? (
                  <li>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href={companyData.domain_search_response.twitter}
                      style={{ textDecoration: "none" }}
                      aria-label="Twitter"
                    >
                      <Avatar src={twitterImg} alt="Twitter" />
                      <span>Twitter</span>
                    </a>
                  </li>
                ) : (
                  <>
                    {companyData.twitter_link && (
                      <li>
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href={companyData.twitter_link}
                          style={{ textDecoration: "none" }}
                          aria-label="Twitter"
                        >
                          <Avatar src={twitterImg} alt="Twitter" />
                          <span>Twitter</span>
                        </a>
                      </li>
                    )}
                  </>
                )}

                {companyData?.domain_search_response?.facebook ? (
                  <li>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href={companyData.domain_search_response.facebook}
                      style={{ textDecoration: "none" }}
                      aria-label="Facebook"
                    >
                      <Avatar src={facebookImg} alt="Facebook" />
                      <span>Facebook</span>
                    </a>
                  </li>
                ) : (
                  <>
                    {companyData.facebook_link && (
                      <li>
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href={companyData.facebook_link}
                          style={{ textDecoration: "none" }}
                          aria-label="facebook"
                        >
                          <Avatar src={facebookImg} alt="Facebook" />
                          <span>Facebook</span>
                        </a>
                      </li>
                    )}
                  </>
                )}

                {companyData?.domain_search_response?.crunchbase ? (
                  <li>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href={companyData.domain_search_response.crunchbase}
                      style={{ textDecoration: "none" }}
                      aria-label="crunchbase"
                    >
                      <Avatar src={crunchImg} alt="Crunchbase" />
                      <span>Crunchbase</span>
                    </a>
                  </li>
                ) : (
                  <>
                    {companyData.crunchbase_link && (
                      <li>
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href={companyData.crunchbase_link}
                          style={{ textDecoration: "none" }}
                          aria-label="Crunchbase"
                        >
                          <Avatar src={crunchImg} alt="Crunchbase" />
                          <span>Crunchbase</span>
                        </a>
                      </li>
                    )}
                  </>
                )}

                {companyData?.domain_search_response?.wikipedia ? (
                  <li>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href={companyData.domain_search_response.wikipedia}
                      style={{ textDecoration: "none" }}
                      aria-label="Wikipedia"
                    >
                      <Avatar src={wikipediaImg} alt="Wikipedia" />
                      <span>Wikipedia</span>
                    </a>
                  </li>
                ) : (
                  <>
                    {companyData.wikipedia_link && (
                      <li>
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href={companyData.wikipedia_link}
                          style={{ textDecoration: "none" }}
                          aria-label="wikipedia"
                        >
                          <Avatar src={wikipediaImg} alt="Wikipedia" />
                          <span>Wikipedia</span>
                        </a>
                      </li>
                    )}
                  </>
                )}

                {companyData?.domain_search_response?.news ? (
                  <li>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href={companyData.domain_search_response.news}
                      style={{ textDecoration: "none" }}
                      aria-label="news"
                    >
                      <Avatar src={googleImg} alt="News" />
                      <span>News</span>
                    </a>
                  </li>
                ) : (
                  <>
                    {companyData.news_link && (
                      <li>
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href={companyData.news_link}
                          style={{ textDecoration: "none" }}
                          aria-label="News"
                        >
                          <Avatar src={googleImg} alt="News" />
                          <span>News</span>
                        </a>
                      </li>
                    )}
                  </>
                )}

                {companyData?.domain_search_response?.glassdoor ? (
                  <li>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href={companyData.domain_search_response.glassdoor}
                      style={{ textDecoration: "none" }}
                      aria-label="glassdoor"
                    >
                      <Avatar src={glassDoorImg} alt="glassdoor" />
                      <span>Glassdoor</span>
                    </a>
                  </li>
                ) : (
                  <>
                    {companyData.glassdoor_link && (
                      <li>
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href={companyData.glassdoor_link}
                          style={{ textDecoration: "none" }}
                          aria-label="glassdoor"
                        >
                          <Avatar src={glassDoorImg} alt="Glassdoor" />
                          <span>Glassdoor</span>
                        </a>
                      </li>
                    )}
                  </>
                )}

                {companyData?.domain_search_response?.angelist ? (
                  <li>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href={companyData.domain_search_response.angelist}
                      style={{ textDecoration: "none" }}
                      aria-label="angelist"
                    >
                      <Avatar src={angelistImg} alt="angelist" />
                      <span>Agelist</span>
                    </a>
                  </li>
                ) : (
                  <>
                    {companyData.angelist_link && (
                      <li>
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href={companyData.angelist_link}
                          style={{ textDecoration: "none" }}
                          aria-label="angelist"
                        >
                          <Avatar src={angelistImg} alt="Angelist" />
                          <span>Agelist</span>
                        </a>
                      </li>
                    )}
                  </>
                )}

                {/* /indeed_link */}
              </ul>
            </div>
          </React.Fragment>
        )}
      </div>
    </CompanyRightStyles>
  );
};

export default CompanyRight;
