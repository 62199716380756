import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 420px;

  h1 {
    color: #fff;
    font-family: "TTCommons-Regular";
    font-size: 42px;
    line-height: 42px;
    font-weight: 600;
    margin: 30px auto 20px auto;
  }

  .middle-content {
    .title {
      color: #fff;
      font-family: "TTCommons-Regular";
      font-size: 18px;
      line-height: 20px;
      font-weight: 500;
    }

    .benefits-wrapper {
      color: #fff;
      font-family: "TTCommons-Regular";
      font-size: 18px;
      line-height: 19px;
      margin: 30px auto 20px auto;

      .benefit-item {
        display: flex;
        margin-bottom: 15px;

        .benefit-item-image {
          margin-right: 10px;
          img {
            border-radius: 50%;
            height: 15px;
            width: 15px;
          }
        }
      }
    }
  }

  h2 {
    color: #fff;
    font-family: "TTCommons-Regular";
    font-size: 22px;
    line-height: 24px;
    font-weight: 600;
    margin: 0px;
    margin-bottom: 10px;
  }

  @media (max-width: 1366px) {
    width: 80% !important;
    margin-top: 5px;

    img {
      width: 100%;
      height: 100%;
    }

    h1 {
      font-size: 30px;
      line-height: 30px;
      font-weight: 500;
      margin-top: 20px;
    }

    h2 {
      font-size: 18px;
      line-height: 19px;
    }
  }
`;
