import React, { useEffect, useState } from "react";
import { Avatar, Fab } from "@mui/material";
import { MoreHoriz } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import _get from "lodash/get";
import moment from "moment";
import { LinkedIn } from "react-linkedin-login-oauth2";
import { useDispatch, useSelector } from "react-redux";

import { config } from "../../../constant/config";
import { useAppState } from "../../../context";
import { toastify } from "../../../helper/helper";
import { useFetchEducation } from "../../../hooks/Profile/useFetchEducation";
import usePromise from "../../../hooks/usePromise/usePromise";
import { routes } from "../../../routes";
import { signUpSSO } from "../../../services/authenticationServices";
import { getBootProfileAPI } from "../../../services/boostProfileService";
import { ACCESSIBILITY_SECONDARY_COLOR } from "../../../styles/variables";
import { isStaffAccountType } from "../../../utils/common";
import AuthGoogleProfile from "../../AuthGoogle/AuthGoogleProfile";
import ButtonCustom from "../../ButtonCustom/ButtonCustom";
import CardCustom from "../../CardCustom/CardCustom";
import CustomProgressBar from "../../CustomProgressBar/CustomProgressBar";
import DashboardRightSidebar from "../DashboardRightSidebar/DashboardRightSidebar";
import DashboardLeftSidebarStyles from "./DashboardLeftSidebarStyles";
import DashboardMenus from "./DashboardMenus/DashboardMenus";
import DashboardSocialPopup from "./DashboardSocialPopup/DashboardSocialPopup";
import DashboardInviteFriendPopup from "./DashboardInviteFriendPopup/DashboardInviteFriendPopup";
import DashboardAmbassadorInfoPopup from "./DashboardAmbassadorInfoPopup/DashboardAmbassadorInfoPopup";
import { updateUsersPersistState } from "../../../redux/reducers/userReducer";

import ArrowIcon from "../../../assets/svg/ArrowIcon";
import fArrowIcon from "../../../assets/svg/f-arrow.svg";
import linkedinDashboard from "../../../assets/svg/f-linkedin-logo.svg";
import linkedinDashboardTick from "../../../assets/svg/f-linkedin-synced.svg";
import savedPostIcon from "../../../assets/svg/f-saved.svg";
import redCheckedIcon from "../../../assets/svg/red-check-mark.svg";

const LINKEDIN_CLIENT_ID = config().LINKEDIN_CLIENT_ID;
const LINKEDIN_REDIRECT_URI = config().LINKEDIN_REDIRECT_URI;

const DashboardLeftSidebar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { totalCompletedStatus, statusList, setStatusList, resetBoostStep } =
    useAppState("boostProfile");
  const { saved_user_posts = [] } = useAppState("post");
  const { users = {} } = useSelector((store) => store.userStore);
  const accessibilityHelp = localStorage.getItem("accessibilityHelp");
  const totalSteps = statusList.length;
  const {
    profile_image_url,
    user_profile_url,
    is_google_connected,
    is_linkedin_connected,
    id: userId,
    admission_user_type,
  } = users || {};

  const [isUserConnected, setIsUserConnected] = useState({
    google: is_google_connected ?? false,
    linkedin: is_linkedin_connected ?? false,
  });
  const [showSocialButtonsPopup, setShowSocialButtonsPopup] = useState(false);
  const [ambassadorInfoPopup, setAmbassadorInfoPopup] = useState(false);
  const [showInviteFriendPopup, setShowInviteFriendPopup] = useState(false);

  const { educationsForm } = useFetchEducation();
  const interStrideEducation =
    educationsForm?.length > 0 ? educationsForm[0] : {};

  const hideEducation = isStaffAccountType(
    users?.account_type,
    users?.user_type,
    true
  );

  const [callGetBootProfileAPI, refreshGetBootProfileAPI] =
    usePromise(getBootProfileAPI);
  const [callSignUpSSO, refreshSignUpSSO] = usePromise(signUpSSO);

  useEffect(() => {
    refreshGetBootProfileAPI(userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsUserConnected({
      google: is_google_connected ?? false,
      linkedin: is_linkedin_connected ?? false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  useEffect(() => {
    if (
      callGetBootProfileAPI.data() &&
      callGetBootProfileAPI.data().boost_profile
    ) {
      setStatusList(callGetBootProfileAPI.data().boost_profile);
    } else {
      resetBoostStep();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callGetBootProfileAPI.hasFetched()]);

  const handleSuccess = (data) => {
    refreshSignUpSSO(userId, "linkedin", data.code, LINKEDIN_REDIRECT_URI);
  };

  const handleFailure = (error) => {
    toastify("error", error.errorMessage);
    setIsUserConnected({ ...isUserConnected, linkedin: false });
    console.log(error.errorMessage);
  };

  useEffect(() => {
    if (callSignUpSSO.hasFetched()) {
      if (callSignUpSSO.hasErrors()) {
        setIsUserConnected({ ...isUserConnected, linkedin: false });
        dispatch(updateUsersPersistState({ is_linkedin_connected: false }));
        if (callSignUpSSO.error().data) {
          if (
            callSignUpSSO.error().data.message &&
            Array.isArray(callSignUpSSO.error().data.message.email) &&
            callSignUpSSO.error().data.message.email
          ) {
            toastify(
              "error",
              "This user already exists in our system. Please contact us at contact@interstride.com."
            );
          } else {
            toastify("error", callSignUpSSO.error().data.message);
          }
        } else {
          toastify("error", "Something went wrong. Please try again later.");
        }
      } else {
        setIsUserConnected({ ...isUserConnected, linkedin: true });
        dispatch(updateUsersPersistState({ is_linkedin_connected: true }));
        toastify(
          "success",
          "Your LinkedIn account was successfully connected."
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callSignUpSSO.isFetching()]);

  // Compute major and year string
  const getMajorAndYear = () => {
    let displayArray = [];

    // Fetch major
    if (interStrideEducation?.major) {
      displayArray.push(interStrideEducation?.major);
    } else if (_get(users, "major", "")) {
      displayArray.push(_get(users, "major", ""));
    }

    // Fetch graduation year
    if (
      interStrideEducation?.graduation_date &&
      moment(interStrideEducation?.graduation_date).isValid()
    ) {
      displayArray.push(
        moment(interStrideEducation?.graduation_date).format("YYYY")
      );
    } else if (_get(users, "graduation_year", "")) {
      displayArray.push(_get(users, "graduation_year", ""));
    }

    return displayArray.join(", ");
  };

  return (
    <DashboardLeftSidebarStyles>
      <div className="dashboard-leftsidebar">
        <CardCustom className="boost-profile-card">
          <div className="profile">
            <div className="profile-icon">
              <Avatar
                src={profile_image_url || user_profile_url || ""}
                alt={users.name || "user profile"}
              />
            </div>
            <div className="profile-description">
              <div className="profile-description-line-one">
                <h2 className="profile-name">{users.name}</h2>
                {admission_user_type && (
                  <a
                    href="/#"
                    className="profile-ambassador-tag no-underline"
                    onClick={(e) => {
                      e.preventDefault();
                      setAmbassadorInfoPopup(true);
                    }}
                  >
                    <img src={redCheckedIcon} alt="red-check" />
                    <span>Ambassador</span>
                  </a>
                )}
              </div>
              {!hideEducation && (
                <div className="bio" style={{ textTransform: "capitalize" }}>
                  {getMajorAndYear()}
                </div>
              )}
              <div className="display-widgets">
                <AuthGoogleProfile
                  isDashboardWidget={true}
                  setIsUserConnected={setIsUserConnected}
                  isUserConnected={isUserConnected}
                  isGoogleConnected={isUserConnected?.google}
                  isDisabled={isUserConnected?.google}
                />
                <div className="widgets-item">
                  <LinkedIn
                    className="linkedin-icon"
                    clientId={LINKEDIN_CLIENT_ID}
                    redirectUri={LINKEDIN_REDIRECT_URI}
                    scope="r_liteprofile,r_emailaddress"
                    state="34232423"
                    onFailure={handleFailure}
                    onSuccess={handleSuccess}
                    disabled={
                      isUserConnected.linkedin || callSignUpSSO.isFetching()
                    }
                    supportIE
                    redirectPath="/linkedin"
                  >
                    <div
                      style={{
                        cursor:
                          isUserConnected.linkedin || callSignUpSSO.isFetching()
                            ? "not-allowed"
                            : "pointer",
                      }}
                    >
                      <img
                        src={
                          isUserConnected.linkedin
                            ? linkedinDashboardTick
                            : linkedinDashboard
                        }
                        alt="Linked In"
                        className="icon"
                      />
                    </div>
                  </LinkedIn>
                </div>

                {!isUserConnected?.linkedin && !isUserConnected?.google && (
                  <a
                    href="/#"
                    className="connect-label cursor-pointer no-underline"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowSocialButtonsPopup(!showSocialButtonsPopup);
                    }}
                  >
                    <span>Connect accounts</span>
                  </a>
                )}
              </div>
            </div>
          </div>
          <div className="boost-profile">
            <h2 className="title">
              {totalCompletedStatus === totalSteps
                ? "Congratulations "
                : "Boost your profile!"}
            </h2>
            <div className="sub-title">
              {totalCompletedStatus === totalSteps
                ? "You're in the elite group of Interstride users."
                : "Boost your profile by trying some of the features on Interstride."}
            </div>

            {accessibilityHelp === "true" && (
              <div className="sub-title">
                {totalCompletedStatus} out of {totalSteps}
              </div>
            )}
            <CustomProgressBar
              width={"100%"}
              height={22}
              percent={(totalCompletedStatus + 1) * (100 / totalSteps)}
              totalStep={totalSteps}
              currentStep={totalCompletedStatus + 1}
              isDisplayLabel={false}
              unfilledBackground={"var(--icon_background_hover)"}
              filledBackground={
                accessibilityHelp === "true"
                  ? ACCESSIBILITY_SECONDARY_COLOR
                  : "var(--primary-main-color)"
              }
            />

            {totalCompletedStatus < totalSteps ? (
              <>
                <ButtonCustom
                  width={248}
                  onClick={() => navigate(routes.BOOST_PROFILE)}
                  className="focus-white"
                >
                  Boost Now!
                </ButtonCustom>
              </>
            ) : (
              <div className="text-center">
                <a
                  href="/#"
                  className="nlink-secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(routes.BOOST_PROFILE);
                  }}
                  aria-label="See all actions"
                >
                  See all actions
                </a>
              </div>
            )}
          </div>
        </CardCustom>
        <div className="saved-post-card no-underline">
          <CardCustom
            className={`save-card cursor-pointer mt-16 ${
              saved_user_posts.length < 1 && "no-save-post"
            }`}
            onClick={() => {
              if (saved_user_posts.length > 0) navigate(routes.SAVED_POSTS);
            }}
          >
            {saved_user_posts.length > 0 ? (
              <div className="save-posts">
                <div className="posts" id={`saved-post-section-title`}>
                  <img
                    className="ml-auto"
                    src={savedPostIcon}
                    alt="saved Post Icon"
                  />
                  Your saved posts ({saved_user_posts.length})
                </div>
                <div className="posts-count">
                  <div>
                    <img
                      className="ml-auto"
                      src={fArrowIcon}
                      alt="Arrow Icon"
                    />
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="save-posts">
                  <h3 className="posts">No Saved Posts yet!</h3>
                </div>
                <div className="save-post-dec d-flex align-items-center">
                  <div
                    className="posts-dec text-gray"
                    style={{ width: "189px" }}
                  >
                    Click on the More icon to save a post so you can visit it
                    later.
                  </div>

                  <ArrowIcon className="ml-auto" color="#8094AB" />
                  <Fab
                    size="small"
                    color="inherit"
                    aria-label="More button to save posts"
                    disabled={true}
                    disableRipple={true}
                  >
                    <MoreHoriz color="primary" />
                  </Fab>
                </div>
              </>
            )}
          </CardCustom>
        </div>
        <div className="invite-friend">
          <h2 className="invite-friend__heading">Invite a friend</h2>
          <p className="invite-friend__body">
            You can invite your friend to this student portal.
          </p>
          <ButtonCustom
            width={248}
            onClick={() => setShowInviteFriendPopup(true)}
            className="focus-white"
          >
            Send Invite
          </ButtonCustom>
        </div>
        <div className="ShowResponsiveDiv">
          <DashboardRightSidebar isResponsive />
        </div>
        <div>
          <DashboardMenus />
        </div>
        {showSocialButtonsPopup && (
          <DashboardSocialPopup
            setIsUserConnected={setIsUserConnected}
            isUserConnected={isUserConnected}
            handleClose={() => setShowSocialButtonsPopup(false)}
            callGetBootProfileAPI={callGetBootProfileAPI}
            open={showSocialButtonsPopup}
          />
        )}
        {ambassadorInfoPopup && admission_user_type && (
          <DashboardAmbassadorInfoPopup
            ambassadorInfoPopup={ambassadorInfoPopup}
            setAmbassadorInfoPopup={setAmbassadorInfoPopup}
          />
        )}
        {showInviteFriendPopup && (
          <DashboardInviteFriendPopup
            handleClose={() => setShowInviteFriendPopup(false)}
            open={showInviteFriendPopup}
          />
        )}
      </div>
    </DashboardLeftSidebarStyles>
  );
};

export default DashboardLeftSidebar;
