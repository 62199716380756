import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { Tooltip, Typography } from "@mui/material";
import { useNavigate } from "react-router";

import styles from "./TopRecommendedJobs.module.scss";
import { createModuleStyleExtractor } from "../../../utils/css";
import { getRecommendedJobs } from "../../../services/JobServices";
import { toastify } from "../../../helper/helper";
import JobsListingEffect from "../../effects/JobsListingEffect/JobsListingEffect";
import CardCustom from "../../CardCustom/CardCustom";
import InitialAvatar from "../../InitialAvatar/InitialAvatar";
import {
  capitalizeFirstLetter,
  processJobLocationTime,
  numberWithCommas,
} from "../../../helper/helper";
import { useSavedJobs } from "../../../hooks/useSavedJobs";
import StarIcon from "../../StarIcon/StarIcon";
import usePromise from "../../../hooks/usePromise/usePromise";
import { updateFavouriteJobsAPI } from "../../../services/JobServices";
import InfoIconTooltip from "../../InfoIconTooltip/InfoIconTooltip";
import { STUDENT_VIEW_PREFERENCES_OPTIONS } from "../../../utils/common";

const cx = createModuleStyleExtractor(styles);

export default function TopRecommendedJobs({
  jobsToDisplayCount = 10,
  defaultCountry = "us",
  handleViewAllClick = () => {},
  hideSection = () => {},
}) {
  const navigate = useNavigate();
  const [data, setData] = useState({
    apiLoading: false,
    apiData: {},
  });

  const accessibilityHelp = localStorage.getItem("accessibilityHelp") || false;
  const { users = {} } = useSelector((store) => store.userStore);
  const { savedJobs = [], refreshSavedJobs = () => {} } = useSavedJobs();
  const [callingUpdateFavouriteJobsAPI, refreshUpdateFavouriteJobsAPI] =
    usePromise(updateFavouriteJobsAPI);

  const favJobsKeyList = useMemo(
    () => savedJobs.map(({ job_id }) => job_id),
    [savedJobs]
  );

  const updateFavourites = (params = {}) => {
    refreshUpdateFavouriteJobsAPI(params);
  };

  const handleViewAllTagClick = (e = {}) => {
    e?.preventDefault?.();
    handleViewAllClick(data?.apiData?.filters || []);
    window?.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleApplyClick = (e = {}, job = {}) => {
    e?.preventDefault?.();
    job?.id &&
      window.open(`/jobs/detail/${job.id}`, "_blank", "noopener,noreferrer");
  };

  useEffect(() => {
    (async () => {
      setData({ apiLoading: true, apiData: {} });
      const response = await getRecommendedJobs({
        country: defaultCountry,
        page: 1,
      });
      if (response?.success && response.data) {
        setData({
          apiLoading: false,
          apiData: {
            ...response?.data,
            jobs: response?.data?.jobs?.slice(0, jobsToDisplayCount),
          },
        });
        if (response?.data?.total_jobs === 0) hideSection();
      } else {
        setData({ apiLoading: false, apiData: {} });
        toastify("error", response?.message || "");
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      callingUpdateFavouriteJobsAPI.hasFetched() &&
      callingUpdateFavouriteJobsAPI.hasErrors() === false &&
      callingUpdateFavouriteJobsAPI.data()?.message
    ) {
      toastify("success", callingUpdateFavouriteJobsAPI.data().message);
      refreshSavedJobs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingUpdateFavouriteJobsAPI.isFetching()]);

  return (
    <div className={cx("top-recommended-jobs")}>
      {data?.apiLoading ? (
        <JobsListingEffect />
      ) : (
        <>
          <div className={cx("top-recommended-jobs__header")}>
            <h2 className={cx("top-recommended-jobs__header__title")}>
              Recommended Jobs
              {data?.apiData?.total_jobs > 0 &&
                ` (${numberWithCommas(data.apiData.total_jobs)})`}
              <InfoIconTooltip
                variant="Header"
                aria-label="featured jobs note"
                placement="bottom"
                content={
                  <Typography>
                    <p>We have recommended jobs based on your profile.</p>
                    <p>
                      You can update your profile{" "}
                      <span
                        className={cx(
                          "top-recommended-jobs__header__title__redirect-text"
                        )}
                        onClick={() => navigate("/profile")}
                      >
                        here
                      </span>
                    </p>
                  </Typography>
                }
              />
            </h2>
            {data?.apiData?.total_jobs > 0 && (
              <a
                href="/#"
                onClick={handleViewAllTagClick}
                className={cx("top-recommended-jobs__header__view-all")}
              >
                View All
              </a>
            )}
          </div>
          <div className={cx("top-recommended-jobs__listing")}>
            {data?.apiData?.total_jobs === 0 ? (
              <CardCustom
                className={cx("top-recommended-jobs__listing__item__card")}
              >
                <p
                  className={cx(
                    "top-recommended-jobs__listing__item__no-job-text"
                  )}
                >
                  No recommended jobs available
                </p>
              </CardCustom>
            ) : (
              data?.apiData?.jobs?.map((job, index) => (
                <div
                  className={cx("top-recommended-jobs__listing__item")}
                  key={index}
                >
                  <CardCustom
                    className={cx("top-recommended-jobs__listing__item__card")}
                  >
                    <Tooltip
                      title={
                        job?.ai_summary ||
                        (users?.student_view_preference ===
                          STUDENT_VIEW_PREFERENCES_OPTIONS.INTERNATIONAL &&
                          job?.no_of_petitions > 0) ? (
                          <div
                            className={cx(
                              "top-recommended-jobs__listing__item__job__tooltip"
                            )}
                          >
                            {job?.ai_summary && (
                              <p
                                className={cx(
                                  "top-recommended-jobs__listing__item__job__tooltip__line-one"
                                )}
                              >
                                {job?.ai_summary}
                              </p>
                            )}
                            {users?.student_view_preference ===
                              STUDENT_VIEW_PREFERENCES_OPTIONS.INTERNATIONAL &&
                              job?.no_of_petitions > 0 && (
                                <p>
                                  {`2024 H-1B Petitions: ${job?.no_of_petitions}`}
                                </p>
                              )}
                          </div>
                        ) : (
                          ""
                        )
                      }
                      placement="bottom-start"
                      PopperProps={{
                        modifiers: [
                          {
                            name: "offset",
                            options: {
                              offset: [0, -10],
                            },
                          },
                        ],
                      }}
                    >
                      {(job?.sponsored_last_year || job?.visa_sponsorship) && (
                        <div
                          className={cx(
                            "top-recommended-jobs__listing__item__tags"
                          )}
                        >
                          {job?.sponsored_last_year && (
                            <span
                              className={cx(
                                "top-recommended-jobs__listing__item__tags__item"
                              )}
                            >
                              Sponsored last year
                            </span>
                          )}
                          {job?.visa_sponsorship && (
                            <span
                              className={cx(
                                "top-recommended-jobs__listing__item__tags__item"
                              )}
                            >
                              Sponsorship available
                            </span>
                          )}
                        </div>
                      )}
                      <div
                        className={cx(
                          "top-recommended-jobs__listing__item__job"
                        )}
                      >
                        <div
                          className={cx(
                            "top-recommended-jobs__listing__item__job__avatar"
                          )}
                        >
                          <InitialAvatar
                            title={job?.company}
                            index={index}
                            initialCount={1}
                          />
                        </div>

                        <div
                          className={cx(
                            "top-recommended-jobs__listing__item__job__content"
                          )}
                        >
                          <p
                            className={cx(
                              "top-recommended-jobs__listing__item__job__content__title"
                            )}
                          >
                            {capitalizeFirstLetter(job?.job_title)}
                          </p>

                          <p
                            className={cx(
                              "top-recommended-jobs__listing__item__job__content__company"
                            )}
                          >
                            {job?.company}
                          </p>
                          <p
                            className={cx(
                              "top-recommended-jobs__listing__item__job__content__location"
                            )}
                          >
                            {processJobLocationTime({
                              city: job?.city,
                              state: job?.state,
                              country: ![
                                "United States",
                                "USA",
                                "US",
                                "usa",
                                "us",
                              ].includes(job?.country)
                                ? job?.country
                                : null,
                              time: job?.publish_date,
                            })}
                          </p>
                        </div>
                        <div
                          className={cx([
                            "top-recommended-jobs__listing__item__job__actions",
                            accessibilityHelp === "true"
                              ? "top-recommended-jobs__listing__item__job__actions--accessible"
                              : "",
                          ])}
                        >
                          <a
                            href="/#"
                            className={cx(
                              "top-recommended-jobs__listing__item__job__actions__apply"
                            )}
                            aria-label="Apply"
                            onClick={(e) => handleApplyClick(e, job)}
                          >
                            View
                          </a>
                          {favJobsKeyList.includes(job?.job_key) ? (
                            <StarIcon
                              aria-label={`Remove ${job.job_title} from saved jobs`}
                              variant="filled"
                              onClick={() =>
                                updateFavourites({
                                  ...job,
                                  userId: users?.id,
                                  job_id: job.job_key,
                                  like: "unlike",
                                })
                              }
                            />
                          ) : (
                            <StarIcon
                              aria-label={`Add ${job.job_title} to saved jobs`}
                              onClick={() => {
                                updateFavourites({
                                  ...job,
                                  userId: users?.id,
                                  job_id: job.job_key,
                                  like: "like",
                                });
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </Tooltip>
                  </CardCustom>
                </div>
              ))
            )}
          </div>
        </>
      )}
    </div>
  );
}
