import React, { useLayoutEffect } from "react";
import { Grid } from "@mui/material";
import { Done } from "@mui/icons-material";

import OnboardingLayoutStyles from "./OnboardingLayoutStyles";
import OnboardingHeader from "./Header/OnboardingHeader";
import AmbassadorInvite from "./AmbassadorInvite/AmbassadorInvite";
import { addAccessibilityClass } from "../../../helper/helper";
import backImg from "../../../assets/Illustration.png";

const InvitedSteps = [
  "Tell us a little bit about you.",
  "Tell us about your program.",
  "Last step! Verify your email.",
];

const NonInvitedSteps = [
  "Verify your email address.",
  "Tell us about your program.",
  "Waiting for approval.",
];

const OnboardingLayout = ({
  isDisplayContent = false,
  navigateBackUrl = "",
  activeStep = 1,
  children,
  title,
  title2,
  subtitle,
  success,
  viewLeftContainer = true,
  viewAmbassadorSection = false,
  type = "invited", // invited | non-invited
}) => {
  let steps = type === "invited" ? InvitedSteps : NonInvitedSteps;

  if (success === true) {
    steps[2] = "Success.";
  }

  useLayoutEffect(() => {
    document.body.className = "interstride-app-body__authlayout";
    addAccessibilityClass();
  }, []);

  return (
    <OnboardingLayoutStyles backgroundImg={backImg}>
      <main role="main">
        <div
          className={`onboarding-layout ${
            viewAmbassadorSection ? "ambassador-content" : ""
          }`}
        >
          <Grid container spacing={0}>
            {viewLeftContainer && (
              <Grid item xs={6} className="onboarding-left-sidebar">
                <div className="left-sidebar-content">
                  <ol>
                    {isDisplayContent &&
                      steps.map((item, i) => (
                        <li
                          className={activeStep === i + 1 ? "active" : ""}
                          key={i}
                        >
                          {i < activeStep - 1 ? (
                            <span style={{ fontSize: 0 }}>
                              completed
                              <Done />
                            </span>
                          ) : (
                            <span> {i + 1}</span>
                          )}

                          {/* eslint-disable-next-line */}
                          <h1 role="Presentation" key={i}>
                            {item}
                          </h1>
                        </li>
                      ))}
                  </ol>
                  {viewAmbassadorSection && <AmbassadorInvite />}
                </div>
              </Grid>
            )}
            <Grid item xs={6} className="right-sidebar">
              <div className="onboarding-wrapper">
                <OnboardingHeader
                  title={title}
                  title2={title2}
                  subtitle={subtitle}
                  navigateBackUrl={navigateBackUrl}
                />
                {children}
              </div>
            </Grid>
          </Grid>
        </div>
      </main>
    </OnboardingLayoutStyles>
  );
};

export default OnboardingLayout;
