import React, { useState } from "react";
import { useFormik } from "formik";
import isEmail from "validator/lib/isEmail";
import { useSelector } from "react-redux";

import TextboxCustom from "../../../Textbox/TextboxCustom";
import ButtonCustom from "../../../ButtonCustom/ButtonCustom";
import CustomDialog from "../../../CustomDialog/CustomDialog";
import { toastify } from "../../../../helper/helper";
import { inviteFriendAPI } from "../../../../services/dashboardServices";

export default function DashboardInviteFriendPopup({
  open = false,
  handleClose = () => {},
}) {
  const { school_id = null } = useSelector((store) => store?.userStore?.users);
  const [apiLoading, setApiLoading] = useState(false);

  const validate = (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = "Please enter an email";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validate,
    onSubmit: async (values) => {
      // Check for valid email
      if (!isEmail(values.email)) {
        toastify("error", "Please enter a valid email");
      } else {
        try {
          setApiLoading(true);

          const formData = new FormData();
          formData.append("email", values?.email?.trim());
          school_id && formData.append("school_id", school_id);

          const response = await inviteFriendAPI(formData);

          setApiLoading(false);
          if (response?.success) {
            toastify("success", response?.message);
            handleClose();
          } else {
            toastify("error", response?.message || "");
          }
        } catch (error) {
          setApiLoading(false);
          toastify("error", error?.data?.message || "");
        }
      }
    },
  });
  return (
    <CustomDialog
      title="Invite a friend"
      subTitle="Please enter email of a friend to invite"
      open={open}
      handleClose={handleClose}
      padding={50}
      className="sync-events-popup"
      dialogTitleClass="modal-title"
      dialogSubTitleClass="text-para"
    >
      <form onSubmit={formik.handleSubmit}>
        <div className="invite-friend">
          <div className="invite-friend__form-field-group">
            <TextboxCustom
              label="Friend's email"
              name="Friend's email"
              placeholder="Enter friend's email"
              required={true}
              value={formik.values.email}
              onChange={(ev) => formik.setFieldValue("email", ev.target.value)}
            />
          </div>
          <ButtonCustom
            width={191}
            onClick={() => formik.handleSubmit()}
            className="focus-white"
            isDisabled={formik.values.email?.length === 0}
            isSubmitting={apiLoading}
          >
            Submit
          </ButtonCustom>
        </div>
      </form>
    </CustomDialog>
  );
}
