import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { cloneDeep, isEmpty } from "lodash";
import { Grid, Typography } from "@mui/material";

import BoostProfileLeftSidebar from "../../../components/BoostProfile/BoostProfileLeftSidebar/BoostProfileLeftSidebar";
import ButtonCustom from "../../../components/ButtonCustom/ButtonCustom";
import OPTCalculatorMiddle from "./OPTCalculatorMiddle/OPTCalculatorMiddle";
import OPTCalculatorRightSidebar from "./OPTCalculatorRightSidebar/OPTCalculatorRightSidebar";
import {
  resetOPTInitialState,
  updateOPTIndex,
  updateOPTRightSidebarStatus,
} from "../../../redux/reducers/optReducer";
import { getDateDiffInDays } from "../../../utils/common";
import OPTCalculatorStyles from "./OPTCalculatorStyles";
import InfoIconTooltip from "../../../components/InfoIconTooltip/InfoIconTooltip";

import btnArrow from "../../../assets/svg/f-btn-arrow.svg";

const syncFullTimeCPTUsed = (
  full_time_cpt_used = {},
  optRightSidebarStatus = []
) => {
  if (optRightSidebarStatus[0][0]) {
    const clonedRightSidebarStatus = cloneDeep(optRightSidebarStatus);
    const total = full_time_cpt_used?.value?.reduce(getDateDiffInDays, 0);
    clonedRightSidebarStatus[0][0].value = total;
    return clonedRightSidebarStatus;
  }
  return optRightSidebarStatus;
};

const syncAvailablePostCompletionOPT = (
  available_post_completion_opt = {},
  optRightSidebarStatus = []
) => {
  const fullTimeTotal = available_post_completion_opt?.value
    ?.filter((val) => !val?.isPartTime)
    .reduce(getDateDiffInDays, 0);

  const partTimeTotal = available_post_completion_opt?.value
    ?.filter((val) => val?.isPartTime)
    .reduce(getDateDiffInDays, 0);

  const total =
    (partTimeTotal ? Number(partTimeTotal) / 2 : 0) +
    (fullTimeTotal ? Number(fullTimeTotal) : 0);

  const clonedRightSidebarStatus = cloneDeep(optRightSidebarStatus);
  let available_opt = 0;
  if (clonedRightSidebarStatus[1][0]) {
    available_opt = clonedRightSidebarStatus[1][0].value - total;
  }

  if (clonedRightSidebarStatus[1][1]) {
    clonedRightSidebarStatus[1][1].value = total;
  }

  if (clonedRightSidebarStatus[1][2]) {
    clonedRightSidebarStatus[1][2].value =
      available_opt > 0 ? available_opt : 0;
  }

  return clonedRightSidebarStatus;
};

const syncDeadlinesPostCompletionOPT = (
  deadlines_post_completion_opt = {},
  optRightSidebarStatus = []
) => {
  const add60Days = moment(deadlines_post_completion_opt?.value)
    .add(60, "d")
    .format("MMMM DD, YYYY");
  const earliestI765USCISDate = moment(deadlines_post_completion_opt?.value)
    .subtract(90, "d")
    .format("MMMM DD, YYYY");
  const postCompletionOPTRange = `${moment(deadlines_post_completion_opt?.value)
    .add(1, "d")
    .format("MMMM DD, YYYY")} and ${add60Days}`;
  const clonedRightSidebarStatus = cloneDeep(optRightSidebarStatus);

  if (clonedRightSidebarStatus[2][0]) {
    clonedRightSidebarStatus[2][0].value = add60Days; // sevisDeadline
  }

  if (clonedRightSidebarStatus[2][1]) {
    clonedRightSidebarStatus[2][1].value = earliestI765USCISDate;
  }

  if (clonedRightSidebarStatus[2][2]) {
    clonedRightSidebarStatus[2][2].value = add60Days; // I765USCISDeadline
  }

  if (clonedRightSidebarStatus[2][3]) {
    clonedRightSidebarStatus[2][3].value = postCompletionOPTRange;
  }

  return clonedRightSidebarStatus;
};

const syncFormI765FilingDeadline = (
  form_i765_filing_deadline = {},
  optRightSidebarStatus = []
) => {
  if (optRightSidebarStatus[3][0]) {
    const clonedRightSidebarStatus = cloneDeep(optRightSidebarStatus);
    const i765Deadline = moment(form_i765_filing_deadline?.value).isValid()
      ? moment(form_i765_filing_deadline?.value)
          .add(30, "d")
          .format("MMMM DD, YYYY")
      : "";
    clonedRightSidebarStatus[3][0].value = i765Deadline;
    return clonedRightSidebarStatus;
  }
  return optRightSidebarStatus;
};

const OPTCalculator = () => {
  window.document.title = "OPT Calculator | Interstride"; // Assigns page title
  const [restoringState, setRestoringState] = useState(false);
  const dispatch = useDispatch();

  const {
    optCalculatorState = {},
    optTotalCompletedStatus = 0,
    optStatusList = [],
    optRightSidebarStatus = [],
    optIndex = null,
  } = useSelector((store) => store.optStore);

  const {
    full_time_cpt_used = {},
    available_post_completion_opt = {},
    deadlines_post_completion_opt = {},
    form_i765_filing_deadline = {},
  } = optCalculatorState;

  useEffect(() => {
    if (
      optRightSidebarStatus?.length === 0 ||
      optStatusList?.length === 0 ||
      isEmpty(optCalculatorState)
    ) {
      setRestoringState(true);
      dispatch(resetOPTInitialState());
      setRestoringState(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      full_time_cpt_used &&
      !isEmpty(full_time_cpt_used) &&
      !restoringState &&
      optRightSidebarStatus.length !== 0
    ) {
      // calculate diff for all the arrays
      dispatch(
        updateOPTRightSidebarStatus(
          syncFullTimeCPTUsed(full_time_cpt_used, optRightSidebarStatus)
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [full_time_cpt_used]);

  useEffect(() => {
    if (
      available_post_completion_opt &&
      !isEmpty(available_post_completion_opt) &&
      !restoringState &&
      optRightSidebarStatus.length !== 0
    ) {
      // calculate diff for all the arrays
      dispatch(
        updateOPTRightSidebarStatus(
          syncAvailablePostCompletionOPT(
            available_post_completion_opt,
            optRightSidebarStatus
          )
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [available_post_completion_opt]);

  useEffect(() => {
    if (
      deadlines_post_completion_opt &&
      !isEmpty(deadlines_post_completion_opt) &&
      !restoringState &&
      optRightSidebarStatus.length !== 0
    ) {
      // calculate diff for all the arrays
      dispatch(
        updateOPTRightSidebarStatus(
          syncDeadlinesPostCompletionOPT(
            deadlines_post_completion_opt,
            optRightSidebarStatus
          )
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deadlines_post_completion_opt]);

  useEffect(() => {
    if (
      form_i765_filing_deadline &&
      !isEmpty(form_i765_filing_deadline) &&
      !restoringState &&
      optRightSidebarStatus.length !== 0
    ) {
      // calculate diff for all the arrays
      dispatch(
        updateOPTRightSidebarStatus(
          syncFormI765FilingDeadline(
            form_i765_filing_deadline,
            optRightSidebarStatus
          )
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form_i765_filing_deadline]);

  useEffect(() => {
    if (
      optRightSidebarStatus?.length === 0 ||
      optStatusList?.length === 0 ||
      isEmpty(optCalculatorState)
    ) {
      dispatch(resetOPTInitialState());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <OPTCalculatorStyles>
      <div className="custom-container">
        <div
          className={`opt-calculator-content ${
            optIndex === null ? "opt-intro-page" : ""
          }`}
        >
          <h2 className="f-main-title">
            OPT Calculator
            <InfoIconTooltip
              variant="header"
              aria-label="OPT calculator note"
              placement="bottom"
              margin="1px 0px 0px 8px"
              content={
                <Typography>
                  This planning tool for post-competition OPT is not a
                  substitute for applicable legal requirements, nor is it itself
                  a rule or final action by SEVP. Learn more about the OPT
                  calculator:{" "}
                  <a
                    href="https://studyinthestates.dhs.gov/sevis-help-hub/student-records/fm-student-employment/planning-tool-for-post-completion-opt"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="popover-link"
                  >
                    Planning Tool for Post Completion OPT
                  </a>
                </Typography>
              }
            />
          </h2>

          <Grid container spacing={3}>
            <Grid item xs={3}>
              {optIndex !== null && optIndex >= 0 ? (
                <BoostProfileLeftSidebar
                  sidebarTitle={false}
                  statusType={"opt"}
                  currentIndex={optIndex}
                  handleClickStatus={(idx) => dispatch(updateOPTIndex(idx))}
                  statusList={optStatusList}
                  totalCompletedStatus={optTotalCompletedStatus}
                />
              ) : (
                ""
              )}
            </Grid>
            <Grid item xs={6}>
              <OPTCalculatorMiddle />
              <p className="disclaimer">
                Disclaimer: This planning tool for post-completion OPT is not a
                substitute for applicable legal requirements, nor is it itself a
                rule or a final action by SEVP. It is not intended to, does not,
                and may not be relied upon to create any right or benefit,
                substantive or procedural, enforceable at law by any party in
                any administrative, civil, or criminal matter.
                <br />
                <br /> Please contact your ISSS or DSO office for more details.
              </p>
            </Grid>
            <Grid item xs={3}>
              {optIndex !== null && optIndex >= 0 && optIndex < 5 ? (
                <OPTCalculatorRightSidebar />
              ) : optIndex === 5 ? (
                <div className="btn-wrapper">
                  <ButtonCustom
                    width={173}
                    className="shadow-btn"
                    onClick={() => dispatch(resetOPTInitialState())}
                  >
                    Retake questionnaire
                    <img src={btnArrow} alt="btn-arrow" />
                  </ButtonCustom>
                </div>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    </OPTCalculatorStyles>
  );
};

export default OPTCalculator;
