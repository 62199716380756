import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .create-post {
    padding: 20px;
    overflow: visible !important;
    @media (max-width: 767px) {
      padding: 15px;
    }
    .card-content {
      padding: 0;
      position: relative;
      .text-area-wrapper {
        width: 100%;
        position: relative;
        textarea {
          resize: none;
          width: 100%;
          border: 2px solid var(--gray-outline-color);
          border-radius: 10px;
          background-color: #ffffff;
          padding: 21px 20px;
          font-size: 18px;
          line-height: 24px;
          font-family: "TTCommons-Regular", "Apple Color Emoji",
            "NotoColorEmoji-Regular";
          color: var(--primary-dark-color);
          &::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: var(--field-label-color) !important;
          }
          &::-moz-placeholder {
            /* Firefox 19+ */
            color: var(--field-label-color) !important;
          }
          &:-ms-input-placeholder {
            /* IE 10+ */
            color: var(--field-label-color) !important;
          }
          &:-moz-placeholder {
            /* Firefox 18- */
            color: var(--field-label-color) !important;
          }
          // --Focus style
          &:focus {
            border-color: var(--gray-color) !important;
          }
          &:hover {
            border-color: #bfced8;
          }
          /* scroll styling start */
          scrollbar-width: thin !important;
          scrollbar-color: var(--light-gray-color) transparent; /* scroll thumb and track */
          &::-webkit-scrollbar {
            width: 7px; /* width of the entire scrollbar */
            transition: all ease-in-out 500ms;
          }
          &::-webkit-scrollbar-track {
            background: transparent !important; /* color of the tracking area */
            border-radius: 50px;
            width: 7px;
            -webkit-box-shadow: inset 0 0 6px rgba(100, 100, 111, 0.2) 0px 7px
              29px 0px;
            margin: 10px 0 10px 0;
          }
          &::-webkit-scrollbar-thumb {
            background-color: var(
              --light-gray-color
            ) !important; /* color of the scroll thumb */
            border-radius: 50px; /* roundness of the scroll thumb */
            height: 20px;
          }
          &::-webkit-scrollbar-corner {
            border-radius: 50%;
          }
          &::-webkit-scrollbar-thumb:hover {
            border: 0;
            background-color: var(
              --gray-color
            ) !important; /* color of the scroll thumb */
          }
          /* scroll styling end */
        }
      }
      .post-btn {
        width: 100%;
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        position: relative;
        &__left {
          display: flex;
          align-items: flex-start;
          gap: 5px;
          .emoji-picker-wrapper {
            height: 35px;
            .emoji-icon {
              cursor: pointer;
            }
            .emoji-picker {
              position: absolute;
              width: 100%;
              left: 0px;
              top: 40px;
              z-index: 10;
              .emoji-picker-react {
                box-shadow: none !important;
              }
            }
          }
          .attachment-post {
            display: flex;
            align-items: center;
            .remove-icon {
              a {
                height: 35px;
              }
            }
            &__file {
              display: flex;
              align-items: center;
              &__icon {
                margin-right: 8px;
                background: var(--icon_background);
                display: flex;
                align-items: center;
                width: 35px;
                height: 35px;
                padding: 0 !important;
                min-width: 35px;
                transition: all 0.3s;
                border-radius: 100px;
                justify-content: center;
                margin-left: auto;
                cursor: pointer;
                &:hover {
                  background: var(--icon_background_hover);
                }
              }
              &__name {
                color: var(--primary-dark-color);
                font-family: "TTCommons-Medium";
                font-size: 18px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 19px;
                display: inline-block;
                height: 18px;
                white-space: nowrap;
                max-width: 175px;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }
        &__right {
          display: flex;
          align-items: flex-start;
          gap: 10px;
          &__checkbox {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            &__text {
              display: inline-block;
              color: var(--gray-color);
              font-family: "TTCommons-Regular";
              font-size: 18px;
              letter-spacing: 0;
              line-height: 19px;
              margin-top: 2px;
            }
          }
          button {
            height: 35px;
            width: 78px;
            border-radius: 45px;
          }
        }
      }
      .relative-div {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
      }
    }
  }
`;
