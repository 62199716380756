import React, { useEffect, useRef, useState } from "react";
import _find from "lodash/find";
import { useNavigate, useLocation } from "react-router-dom";
import { Grid } from "@mui/material";
import { useDispatch } from "react-redux";

import Container from "../../../components/Container/Container";
import DiscoverFilter from "../../../components/NetworkDashboard/Discover/DiscoverFilter/DiscoverFilter";
import DiscoverPeople from "../../../components/NetworkDashboard/Discover/DiscoverPeople/DiscoverPeople";
import DiscoverStyles from "./DiscoverStyles";
import { useAppState } from "../../../context";
import usePromise from "../../../hooks/usePromise/usePromise";
import {
  toastify,
  getCountryListFromAPI,
  cancel_token,
} from "../../../helper/helper";
import { routes } from "../../../routes";
import { createFreshChatPreview } from "../../../utils/common";
import RecommendationsDetails from "../../../components/NetworkDashboard/RecommendationsDetails/RecommendationsDetails";
import SortByCustom from "../../../components/SortByCustom/SortByCustom";
import CustomDialog from "../../../components/CustomDialog/CustomDialog";
import SavedPostsWidget from "../../../components/SavedPostWidget/SavedPostWidget";
import {
  getFavoriteNetworksAPI,
  getNetworkDiscoverFiltersAPI,
  favorite_Unfavorite_NetworksAPI,
  getDiscoverNetWorkPeopleAPI,
  getRecommendedNetworksAPI,
} from "../../../services/networkServices";
import { setFavoriteUsers } from "../../../redux/reducers/networkReducer";

import FilterIcon from "../../../assets/svg/filter-Icon.svg";
import FilledFilterIcon from "../../../assets/svg/filled-filter-Icon.svg";

const INITIAL_FILTERS = {
  country: [],
  city: "",
  school: [],
  account_type: [],
  degree: [],
  major: [],
  graduation_year: [],
};
const SORT_BY_OPTIONS = [
  { value: "name", label: "Name" },
  { value: "recommended", label: "Recommended" },
];

const Discover = () => {
  // Hooks
  const searchAPIRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [
    callingGetNetworkDiscoverFiltersAPI,
    refreshGetNetworkDiscoverFiltersAPI,
  ] = usePromise(getNetworkDiscoverFiltersAPI);
  const [callingGetFavoriteNetworksAPI, refreshGetFavoriteNetworksAPI] =
    usePromise(getFavoriteNetworksAPI);
  const [
    callingFavorite_Unfavorite_NetworksAPI,
    refreshFavorite_Unfavorite_NetworksAPI,
  ] = usePromise(favorite_Unfavorite_NetworksAPI);

  // Constants and Variables
  const query = new URLSearchParams(useLocation().search);
  const countryParam = query.get("country");
  const cityParam = query.get("city");
  const USER_ID = new URLSearchParams(window.location.search).get("user_id");

  // States - Local
  const [netwokDiscoverOptions, setNetwokDiscoverOptions] = useState({});
  const [activePanelIndex, setActivePanelIndex] = useState(null);
  const [showViewModal, setShowViewModal] = useState(false);
  const [openFilter, toggleFilter] = useState(false);
  const [sortBy, setSortBy] = useState("recommended");
  const [selectedUserData, setSelectedUserData] = useState(false);
  const [currentFilters, setCurrentFilters] = useState({
    ...INITIAL_FILTERS,
    ...(cityParam ? { city: cityParam } : {}),
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [showLoader, setShowLoader] = useState(true);

  // States - Global
  const {
    setDiscoverNetworks,
    discoverNetworks,
    setUserDetails,
    recommandedUserDetails,
    shouldClearDiscoverFilter,
    setShouldClearDiscoverFilter,
  } = useAppState("network");
  const {
    getChatContactListItem,
    getChatPreviewByReceiverId,
    chats,
    setChats,
    setConversationInformation,
    setCurrentConversationId,
    createFreshChatRecord,
    favoriteUsersCount,
    setFavoriteUsersCount,
  } = useAppState("chat");

  // Functions
  const callDiscoverNetWorkPeopleAPI = async (payload = {}) => {
    try {
      if (searchAPIRef?.current?.cancel) {
        searchAPIRef.current.cancel("Operation cancelled by user");
      }

      const cancelToken = cancel_token();
      searchAPIRef.current = cancelToken;

      const response = await getDiscoverNetWorkPeopleAPI(
        {
          page: 1,
          ...currentFilters,
          ...payload,
        },
        cancelToken.token
      );

      if (response.success && response.data) {
        const { networks_list = [], current_page, total_pages } = response.data;

        const _discoverNetworks =
          current_page === 1
            ? [...networks_list]
            : [...discoverNetworks, ...networks_list];

        setDiscoverNetworks(_discoverNetworks);
        setTotalPages(total_pages);
        setShowLoader(false);
      }
      searchAPIRef.current = null;
    } catch (error) {
      console.error(error);
    }
  };

  const callRecommendedNetworksAPI = async (payload = {}) => {
    try {
      if (searchAPIRef?.current?.cancel) {
        searchAPIRef.current.cancel("Operation cancelled by user");
      }

      const cancelToken = cancel_token();
      searchAPIRef.current = cancelToken;

      const response = await getRecommendedNetworksAPI(
        {
          page: 1,
          ...currentFilters,
          ...payload,
        },
        cancelToken.token
      );

      if (response.success && response.data) {
        const {
          recommended_network = [],
          current_page,
          total_pages,
        } = response.data;

        const _discoverNetworks =
          current_page === 1
            ? [...recommended_network]
            : [...discoverNetworks, ...recommended_network];

        setDiscoverNetworks(_discoverNetworks);
        setTotalPages(total_pages);
        setShowLoader(false);
      }
      searchAPIRef.current = null;
    } catch (error) {
      console.error(error);
    }
  };

  const clearFilters = () => {
    setCurrentFilters({
      ...INITIAL_FILTERS,
    });
  };

  const handleView = (e, index) => {
    if (e) e.stopPropagation();

    setActivePanelIndex(index);
    if (selectedUserData) {
      setSelectedUserData(false);
    }
    // refreshGetUserDetailsAPI(index)
    setUserDetails(_find(discoverNetworks, { id: index }));
    if (window.screen.width <= 1366) {
      setShowViewModal(true);
    }
  };

  const handleClose = (e) => {
    if (e) e.stopPropagation();
    setActivePanelIndex(null);
    setShowViewModal(false);
  };

  const startChatWithNewUser = (e, item) => {
    try {
      const { id } = item;
      const user = getChatContactListItem(id, discoverNetworks);
      const chat_preview = getChatPreviewByReceiverId(id);
      if (chat_preview) {
        setCurrentConversationId(chat_preview.conversation_id);
        // redirect to chat
        navigate("/network/messenger", { state: { userid: id } });
        return;
      }

      const randomConversationId = new Date().getTime();
      const { preview, conversation } = createFreshChatPreview(
        user,
        randomConversationId
      );
      setChats([preview, ...chats]);
      setConversationInformation(randomConversationId, conversation);
      setCurrentConversationId(randomConversationId);
      createFreshChatRecord(randomConversationId); // we will track by this id
      //redirect to chat page
      navigate("/network/messenger", { state: { userid: id } });
    } catch (error) {
      toastify(
        "error",
        "Something went wrong. Please try again later or contact us for support."
      );
    }
  };

  const handleFavorite = (e, userId) => {
    if (e) e.stopPropagation();
    refreshFavorite_Unfavorite_NetworksAPI(userId);
  };

  const applyFilter = (sort = sortBy) => {
    toggleFilter(false);
    setShowLoader(true);

    if (sort === "name") {
      callDiscoverNetWorkPeopleAPI();
    } else {
      callRecommendedNetworksAPI();
    }
    setActivePanelIndex(null);
    setCurrentPage(1);
  };

  const getPaginationData = () => {
    const nextPage = currentPage + 1;
    if (sortBy === "name") {
      callDiscoverNetWorkPeopleAPI({
        page: nextPage,
      });
    } else {
      callRecommendedNetworksAPI({
        page: nextPage,
      });
    }
    setCurrentPage(nextPage);
  };

  // Effects
  useEffect(() => {
    refreshGetNetworkDiscoverFiltersAPI();
    refreshGetFavoriteNetworksAPI();

    // Set element dynamically sticky based on div's height
    const searchFilterDiv = document.querySelector("#sticky-left");

    const widthMedia = window.matchMedia("(max-width: 1365px)");
    const heightMedia = window.matchMedia("(max-height: 660px)");

    if (searchFilterDiv) {
      if (!widthMedia.matches && !heightMedia.matches) {
        const resize_ob = new ResizeObserver(function (entries) {
          // since we are observing only a single element, so we access the first element in entries array
          let rect = entries[0].contentRect;
          let height = rect.height;

          searchFilterDiv.style.position = "sticky";

          // If window height is greater than div height then sticky from begining else stick dynamically to show entire div's content
          searchFilterDiv.style.top =
            height > window.innerHeight ? `calc(100% - ${height}px)` : "80px";
        });

        resize_ob.observe(searchFilterDiv);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      const COUNTRY_OPTIONS = await getCountryListFromAPI();
      if (countryParam) {
        const selectedCountry = COUNTRY_OPTIONS.find(
          ({ code = "" }) => code === countryParam
        );
        if (selectedCountry) {
          setCurrentFilters({
            ...currentFilters,
            country: [selectedCountry.label],
          });
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryParam]);

  useEffect(() => {
    applyFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFilters]);

  useEffect(() => {
    if (shouldClearDiscoverFilter) {
      clearFilters();
      setShouldClearDiscoverFilter(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldClearDiscoverFilter]);

  useEffect(() => {
    if (USER_ID && USER_ID != null) {
      setSelectedUserData(true);
      let btn = window.document.getElementById(USER_ID);
      if (btn && btn != null) {
        window.scrollTo({ top: btn, behavior: "smooth" });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discoverNetworks]);

  useEffect(() => {
    if (
      callingGetNetworkDiscoverFiltersAPI.hasFetched() &&
      callingGetNetworkDiscoverFiltersAPI.hasErrors() === false
    ) {
      setNetwokDiscoverOptions(callingGetNetworkDiscoverFiltersAPI.data().data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingGetNetworkDiscoverFiltersAPI.isFetching()]);

  useEffect(() => {
    if (
      callingFavorite_Unfavorite_NetworksAPI.hasFetched() &&
      callingFavorite_Unfavorite_NetworksAPI.hasErrors() === false
    ) {
      toastify(
        "success",
        callingFavorite_Unfavorite_NetworksAPI.data().message
      );
      refreshGetFavoriteNetworksAPI();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingFavorite_Unfavorite_NetworksAPI.isFetching()]);

  useEffect(() => {
    if (
      callingGetFavoriteNetworksAPI.hasFetched() &&
      callingGetFavoriteNetworksAPI.hasErrors() === false
    ) {
      const { favourite_list = [] } = callingGetFavoriteNetworksAPI.data().data;
      setFavoriteUsersCount(favourite_list.length);
      dispatch(setFavoriteUsers(favourite_list.map(({ id }) => id)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingGetFavoriteNetworksAPI.isFetching()]);

  return (
    <Container>
      <DiscoverStyles>
        <div className="discover-network-wrapper">
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <div id="sticky-left">
                <div className={`collapsible-view ${openFilter ? "open" : ""}`}>
                  <h2 className="title-primary mb-15">
                    Search & filter
                    <a
                      className="ngray-link"
                      href="/#"
                      onClick={(e) => {
                        e.preventDefault();
                        clearFilters();
                      }}
                    >
                      Clear all
                    </a>
                    <a
                      href="/#"
                      onClick={(e) => {
                        e.preventDefault();
                        toggleFilter(!openFilter);
                      }}
                      className="collapse-icon"
                    >
                      {openFilter ? (
                        <img src={FilledFilterIcon} alt="Filter Icon" />
                      ) : (
                        <img src={FilterIcon} alt="Filter Icon" />
                      )}
                    </a>
                  </h2>
                  <div className="search-card">
                    <DiscoverFilter
                      netwokDiscoverOptions={netwokDiscoverOptions}
                      currentFilters={currentFilters}
                      setCurrentFilters={setCurrentFilters}
                    />
                  </div>
                </div>
              </div>
              <div className="ShowResponsiveDiv">
                {
                  <div id="detail-panel">
                    {activePanelIndex && !showViewModal && (
                      <RecommendationsDetails
                        details={recommandedUserDetails}
                        handleClose={handleClose}
                        handleMessage={startChatWithNewUser}
                        handleFavorite={handleFavorite}
                      />
                    )}

                    <SavedPostsWidget
                      widgetText="Your saved network"
                      count={favoriteUsersCount || 0}
                      onClick={(e) => {
                        e.preventDefault();
                        if (favoriteUsersCount && favoriteUsersCount > 0) {
                          navigate(routes.NETWORK.SAVED_NETWORK);
                        }
                      }}
                    />
                  </div>
                }
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="title-boxs">
                <h2 className="title-primary">People</h2>
                {discoverNetworks.length > 0 && (
                  <div className="sort-by ml-auto">
                    <SortByCustom
                      name="topic"
                      label="Name"
                      showLabelAtTop={false}
                      justifyContent="left"
                      options={SORT_BY_OPTIONS}
                      value={
                        sortBy !== null
                          ? SORT_BY_OPTIONS.find(
                              (option) => option.value === sortBy
                            )
                          : ""
                      }
                      isSearchable={true}
                      onChange={(e) => {
                        applyFilter(e.value);
                        setSortBy(e.value);
                      }}
                    />
                  </div>
                )}
              </div>

              <DiscoverPeople
                networks={discoverNetworks}
                showLoader={showLoader}
                activePanelIndex={activePanelIndex}
                handleView={handleView}
                handleMessage={startChatWithNewUser}
                handleClose={handleClose}
                handleFavorite={handleFavorite}
                selectedUserData={selectedUserData}
                userId={USER_ID}
                currentPage={currentPage}
                totalPages={totalPages}
                fetchMoreData={getPaginationData}
              />
            </Grid>
            {
              <Grid item xs={3} id="detail-panel">
                {activePanelIndex && !showViewModal && (
                  <RecommendationsDetails
                    details={recommandedUserDetails}
                    handleClose={handleClose}
                    handleMessage={startChatWithNewUser}
                    handleFavorite={handleFavorite}
                  />
                )}

                <SavedPostsWidget
                  widgetText="Your saved network"
                  count={favoriteUsersCount || 0}
                  onClick={(e) => {
                    e.preventDefault();
                    if (favoriteUsersCount && favoriteUsersCount > 0) {
                      navigate(routes.NETWORK.SAVED_NETWORK);
                    }
                  }}
                />
              </Grid>
            }
          </Grid>

          {showViewModal && (
            <CustomDialog
              open={showViewModal}
              isDisplayCloseButton={false}
              className="mobile-profile-popup nstudent-card-modal"
              dialogTitleClass="modal-title"
              dialogSubTitleClass="text-para"
              width={"auto"}
            >
              <div className="model-body d-flex justify-content-center align-items-center flex-flow-column">
                <RecommendationsDetails
                  details={recommandedUserDetails}
                  handleClose={handleClose}
                  handleMessage={startChatWithNewUser}
                  handleFavorite={handleFavorite}
                />
              </div>
            </CustomDialog>
          )}
        </div>
      </DiscoverStyles>
    </Container>
  );
};

export default Discover;
