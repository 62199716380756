import React from "react";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { useAppState } from "../../../context";
import TopicDiscoverStyles from "./TopicDiscoverStyles";
import { routes } from "../../../routes";
import DiscoverTopic from "../../../components/Topics/Discover/DiscoverTopic";
import Container from "../../../components/Container/Container";
import ButtonCustom from "../../../components/ButtonCustom/ButtonCustom";

import plusIcon from "../../../assets/svg/f-plus.svg";
import backIcon from "../../../assets/svg/back-arrow-v2.svg";

const TopicDiscover = () => {
  const { handleCommunitySubHeaderClick } = useAppState("topic");
  const { permissions = {} } = useSelector((store) => store.userStore);

  const navigate = useNavigate();
  return (
    <TopicDiscoverStyles>
      <div className="discover-header">
        <h2 className="discover-header__title">Discover Communities</h2>
        <div className="discover-header__back">
          <ButtonCustom
            height={50}
            className="shadow-btn"
            onClick={() => handleCommunitySubHeaderClick()}
          >
            <img src={backIcon} alt="back" />
            <span>Back to Community Feed</span>
          </ButtonCustom>
        </div>
        {permissions["community_options"]?.["allow_create_community"] ===
          "true" ||
        permissions["community_options"]?.["allow_create_community"] ===
          true ? (
          <div className="discover-header__new-topic">
            <ButtonCustom
              className="shadow-btn"
              height={50}
              onClick={() => {
                navigate(routes.NETWORK.NEW_TOPIC);
              }}
            >
              <span>Create a new Community</span>
              <img src={plusIcon} alt="plus" />
            </ButtonCustom>
          </div>
        ) : null}
      </div>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <DiscoverTopic />
          </Grid>
        </Grid>
      </Container>
    </TopicDiscoverStyles>
  );
};

export default TopicDiscover;
