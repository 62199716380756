import styled from "styled-components";
import { PRIMARY_DARK_COLOR } from "../../styles/variables";

export default styled.div.attrs({ className: "" })`
  .description {
    margin: 20px 0 0 0;
    color: ${PRIMARY_DARK_COLOR};
    font-family: "TTCommons-Regular";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
    font-weight: normal;
  }

  .contact-us {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    h4 {
      color: var(--gray-text-color);
      font-family: "TTCommons-Regular";
      font-size: 18px;
      letter-spacing: 0;
      line-height: 25px;
      margin: 0 0 0 0;
      max-width: 330px !important;
    }

    a {
      color: var(--gray-text-color);
      text-decoration: none;
      border-bottom: 2px solid #8094ab;
      display: inline-block;
    }
  }
`;
