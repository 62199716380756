import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { routes } from "../../routes";
import { INTERSTRIDE_DEFAULT_LOGO, toastify } from "../../helper/helper";
import usePromise from "../../hooks/usePromise/usePromise";
import { verifySecondaryEmail } from "../../services/profile.service";
import OnboardingLayout from "../Layouts/OnboardingLayout/OnboardingLayout";
import VerifySecondaryEmailStyles from "./VerifySecondaryEmailStyles";
import ButtonCustom from "../ButtonCustom/ButtonCustom";

const VerifySecondaryEmail = () => {
  const navigate = useNavigate();
  let query = new URLSearchParams(useLocation().search);
  let token = query.get("confirmation_token");
  let email = decodeURIComponent(query.get("email"));

  const [callVerifySecondaryEmail, refreshVerifySecondaryEmail] =
    usePromise(verifySecondaryEmail);
  const [verified, setVerified] = useState(false);

  useEffect(() => {
    if (token && email) refreshVerifySecondaryEmail(token, email);
    else navigate(routes.LOGIN);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      callVerifySecondaryEmail.hasFetched() &&
      callVerifySecondaryEmail.hasErrors() === false
    ) {
      setVerified(true);
      toastify("success", "Secondary email verified successfully.");
    } else if (
      callVerifySecondaryEmail.hasFetched() &&
      callVerifySecondaryEmail.hasErrors()
    ) {
      setVerified(false);
      toastify(
        "error",
        callVerifySecondaryEmail.error().data.message ??
          "Secondary email verification failed."
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callVerifySecondaryEmail.isFetching()]);

  return (
    <VerifySecondaryEmailStyles>
      {callVerifySecondaryEmail.isFetching() ? (
        <div className="page-loader">
          <div className="">
            <img src={INTERSTRIDE_DEFAULT_LOGO} alt="interstride logo" />
            <div className="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      ) : (
        <OnboardingLayout
          title={
            verified
              ? "Email verified successfully!"
              : "Email verification failed."
          }
        >
          {verified && (
            <div className="description">
              Your secondary email address has been verified. You can now use it
              as your primary email address.
            </div>
          )}

          <ButtonCustom
            onClick={() => navigate(routes.LOGIN)}
            style={{ marginBottom: "40px" }}
          >
            Login
          </ButtonCustom>

          <div className="contact-us">
            <h4>
              If you have any questions, please reach out to us at{" "}
              <a href={`mailto:contact@interstride.com`} className="link-focus">
                contact@interstride.com
              </a>
            </h4>
          </div>
        </OnboardingLayout>
      )}
    </VerifySecondaryEmailStyles>
  );
};

export default VerifySecondaryEmail;
