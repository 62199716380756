import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .onboarding-layout {
    overflow: hidden;
    background: #ffffff !important;
    .onboarding-left-sidebar {
      background: url("${(props) => props.backgroundImg}");
      background-size: cover;
      background-color: #000485;

      .left-sidebar-content {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        flex-flow: column;

        ol {
          list-style: none;
          counter-reset: item;
          margin: 0;
          padding: 0;

          li {
            display: flex;
            align-items: center;

            + li {
              margin-top: 40px;
            }

            &.active {
              h1 {
                opacity: 1;
                border-bottom: 2px solid #ffffff;
              }
            }

            h1 {
              margin: 2px 0 0 0;
              margin-left: 20px;
              opacity: 0.7;
              color: #ffffff;
              font-family: "TTCommons-Medium";
              font-size: 30px;
              font-weight: 500;
              letter-spacing: 0;
              line-height: 28px;
            }
            span {
              width: 50px;
              height: 50px;
              background: #0030b5;
              font-size: 30px;
              color: #fff;
              counter-increment: item;
              content: counter(item) "";
              display: -webkit-box;
              display: -webkit-flex;
              display: -ms-flexbox;
              display: flex;
              -webkit-align-items: center;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              -webkit-box-pack: center;
              -webkit-justify-content: center;
              -ms-flex-pack: center;
              justify-content: center;
              border-radius: 50%;
              padding: 8px 0 0;
              line-height: 28px;
              font-family: "TTCommons-Regular";
              svg {
                margin: -6px 0 0 0;
              }
            }
          }
        }
      }
    }
    .right-sidebar {
      height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow: auto;
      position: relative;
      scrollbar-width: thin !important;
      -ms-overflow-style: auto;
      scrollbar-width: thin;
      scrollbar-color: #d1dde5 transparent;
      &:hover {
        overflow-y: auto;
        &::-webkit-scrollbar {
          height: 50px;
        }
      }
      &::-webkit-scrollbar {
        width: 7px;
        height: 50px;
      }
      &::-webkit-scrollbar-track {
        background: transparent !important;
        border-radius: 50px;
        width: 7px;
        margin: 10px 0 10px 0;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #d1dde5 !important;
        border-radius: 50px;
        height: 150px !important;
      }
      &::-webkit-scrollbar-corner {
        border-radius: 50%;
      }
      &::-webkit-scrollbar-thumb:hover {
        border: 0;
        background-color: #607790 !important;
      }
      .onboarding-wrapper {
        display: block;
        width: 100%;
        max-width: 554px;
        margin: 0 auto;
        text-align: center;
        padding: 24px 0 48px;
        form {
          max-width: 325px;
          margin: 0 auto;
        }

        button:not(.MuiIconButton-root) {
          height: auto;
          padding: 17px 35.5px 12px 36.87px;
          border-radius: 45px;
          box-sizing: border-box;
          font-family: "TTCommons-Medium";
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 21px;
          text-align: center;
          display: block;
          min-height: 50px;
          border: 0;
          margin-bottom: 0;
        }
        .social-login {
          margin-top: 30px;
          width: 100%;
          h5 {
            margin-top: 0;
            margin-bottom: 20px;
            color: #000c3d;
            font-family: "TTCommons-Regular";
            font-size: 18px;
            letter-spacing: 0;
            line-height: 20px;
            text-align: center;
            font-weight: 400;
          }
          button {
            margin: 0;
            cursor: pointer;
            + div {
              margin-top: 10px;
            }
          }
        }
      }
    }
  }

  .onboarding-link {
    color: var(--field-label-color);
    font-size: 18px;
    letter-spacing: 0;
    line-height: 22px;
    margin: 0 0 30px 0;
    .text-secondary {
      font-family: "TTCommons-Medium";
      font-size: 18px;
      font-weight: 500;
      line-height: 20px;
      border-bottom: 2px solid var(--link-color) !important;
      text-decoration: none;
      color: var(--link-color) !important;
      margin-left: 4px;
    }
  }

  .ambassador-content {
    .left-sidebar-content {
      height: 100vh !important;
    }

    .right-sidebar {
      height: auto !important;
    }

    @media (max-width: 1024px) {
      .left-sidebar-content {
        justify-content: flex-start !important;
      }
    }
  }
`;
