import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .move-top-button {
    position: sticky;
    bottom: 30px;
    z-index: 9;
    margin-left: auto;
    margin-right: -24px;
    width: 50px;
    height: 50px;
    cursor: pointer;
    border: 0;
    background: transparent;
    padding: 0;
    border-radius: 100px;
    overflow: hidden;
    box-shadow: 0 1px 14px 3px #d1dde5;
    @media (max-width: 1024px) {
      bottom: 90px;
      margin-right: 20px;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
  .dashboard-content {
    .sticky-right-div {
      > div {
        height: 100%;
        .sticky-right-content {
          position: sticky;
          top: 50px;
          @media (max-width: 1365px) {
            position: initial;
            top: initial;
          }
          @media (max-height: 660px) {
            position: initial;
            top: initial;
          }
        }
      }
    }
  }
`;
