import styled from "styled-components";
import { PRIMARY_DARK_COLOR } from "../../../../styles/variables";

export default styled.div.attrs({ className: "" })`
  width: 100%;
  .onboarding-header {
    width: 100%;
    margin-bottom: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    position: relative;
    .back-to-url {
      position: absolute;
      left: -53px;
      top: 56px;
      display: flex;
      align-items: center;
      border-radius: 25px;
      background-color: #ffffff;
      box-shadow: 0 1px 14px 3px rgb(209 221 229 / 40%);
      height: 50px;
      width: 90px;
      justify-content: center;
      text-decoration: none;
      color: #8094ab;
      font-family: "TTCommons-Regular";
      font-size: 18px;
      letter-spacing: 0;
      line-height: 19px;
      transition: all 0.3s;
      @media (max-width: 767px) {
        left: -10px;
        top: 0;
        height: 40px;
      }
      &:hover {
        box-shadow: 0 1px 14px 3px var(--gray-outline-color);
      }
      img {
        margin: 1px 4px 0 -4px;
        height: 15px;
        transform: rotate(180deg);
      }
      span {
        padding-top: 6px;
      }
    }
    .onboarding-sub-header {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 0 28px 0;
      width: 100%;
      .logo {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          height: 36px;
          cursor: pointer;
        }
      }
    }
    .title {
      margin: 0;
      padding: 0;
      font-family: "TTCommons-DemiBold";
      color: ${PRIMARY_DARK_COLOR};
      font-size: 35px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 30px;
      padding-top: 8px;
    }
    .subtitle {
      margin: 20px 0 0 0;
      color: ${PRIMARY_DARK_COLOR};
      font-family: "TTCommons-Regular";
      font-size: 18px;
      letter-spacing: 0;
      line-height: 20px;
      text-align: center;
      font-weight: normal;
      max-width: 330px;
    }
  }
`;
