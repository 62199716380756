import axiosInstance from "../config/axiosInterceptors";
import { headers_with_token } from "../helper/helper";

export const fetchSchools = async (keyword = null) => {
  let url = "/profile/schools";

  if (keyword !== null) {
    url += `?keyword=${keyword}`;
  }

  const response = await axiosInstance.get(url, headers_with_token());
  return response.data;
};
export const fetchUserProfile = async () => {
  const response = await axiosInstance.get(
    `/profile/user_information`,
    headers_with_token()
  );
  return response.data;
};

export const fetchUserProfileById = async (user_id) => {
  const response = await axiosInstance.get(
    `/profile/info?user_id=${user_id}`,
    headers_with_token()
  );
  return response.data;
};

export const updateUserProfile = async (data) => {
  const response = await axiosInstance.patch(
    `/profile/user_information/bulk_update/`,
    data,
    headers_with_token()
  );
  return response.data;
};

export const fetchPersonalInformation = async () => {
  const response = await axiosInstance.get(
    `profile/personal_informations`,
    headers_with_token()
  );
  return response.data;
};

export const updatePersonalInformation = async (data) => {
  const response = await axiosInstance.patch(
    `profile/personal_informations/`,
    data,
    headers_with_token()
  );
  return response.data;
};
export const updateEducationalInformation = async (data) => {
  const response = await axiosInstance.patch(
    `/profile/education/bulk_update`,
    data,
    headers_with_token()
  );
  return response.data;
};
export const updateExperienceInformation = async (data) => {
  const response = await axiosInstance.patch(
    `/profile/experience/bulk_update`,
    data,
    headers_with_token()
  );
  return response.data;
};

export const updateCareerInformation = async (data) => {
  const response = await axiosInstance.patch(
    `/profile/careers`,
    data,
    headers_with_token()
  );
  return response.data;
};

export const updateProfileImage = async (data) => {
  const response = await axiosInstance.patch(
    `profile/personal_information/update_profile`,
    data,
    headers_with_token({ formData: true })
  );
  return response.data;
};

export const fetchEducationInformation = async () => {
  const response = await axiosInstance.get(
    `profile/educations`,
    headers_with_token()
  );
  return response.data;
};

export const fetchCareerInformation = async () => {
  const response = await axiosInstance.get(
    `profile/careers`,
    headers_with_token()
  );
  return response.data;
};

export const fetchExperienceInformation = async () => {
  const response = await axiosInstance.get(
    `profile/experiences`,
    headers_with_token()
  );
  return response.data;
};

export const fetchEssentials = async () => {
  const response = await axiosInstance.get(
    `profile/profile_essentials`,
    headers_with_token()
  );
  return response.data;
};

export const fetchDepartments = async (school_id) => {
  const response = await axiosInstance.get(
    `profile/fetch_departments/${school_id}`,
    headers_with_token()
  );
  return response.data;
};

export const updateResume = async (resume) => {
  const response = await axiosInstance.patch(
    `profile/personal_information/update_resume`,
    resume,
    headers_with_token({ formData: true })
  );
  return response.data;
};

export const removeResume = async () => {
  const response = await axiosInstance.patch(
    `profile/personal_information/update_resume?remove=true`,
    {},
    headers_with_token()
  );
  return response.data;
};

export const changeSecondaryToPrimary = async (email) => {
  const response = await axiosInstance.patch(
    `profile/personal_information/make_primary`,
    { email },
    headers_with_token()
  );

  return response.data;
};

export const verifySecondaryEmail = async (token, email) => {
  const response = await axiosInstance.get(
    `profile/secondary_email/verify?confirmation_token=${token}&secondary_email=${encodeURIComponent(
      email
    )}`,
    headers_with_token()
  );

  return response.data;
};
export const authenticateGoogleSignin = async (code, redirect_uri) => {
  const response = await axiosInstance.post(
    "profile/google_connect",
    { code, redirect_uri },
    headers_with_token()
  );
  return response.data;
};

export const disconnectAccountGoogle = async () => {
  const response = await axiosInstance.delete(
    "/external_user_accounts/disconnect_external_user_account/google",
    headers_with_token()
  );
  return response.data;
};

export const disconnectAccountLinkedin = async () => {
  const response = await axiosInstance.delete(
    "/external_user_accounts/disconnect_external_user_account/linkedin",
    headers_with_token()
  );
  return response.data;
};

export const resendSecondaryEmailVerification = async () => {
  const response = await axiosInstance.post(
    "profile/secondary_email/resend_verification_email",
    {},
    headers_with_token()
  );
  return response.data;
};

export const closeAccount = async () => {
  const response = await axiosInstance.post(
    `/deactivate`,
    {},
    headers_with_token()
  );
  return response.data;
};
