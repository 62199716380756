import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import SaveJobsListStyles from "./SaveJobsListStyles";
import ArrowImg from "../../../assets/svg/DownArrow-02.svg";
import StarIcon from "../../StarIcon/StarIcon";
import usePromise from "../../../hooks/usePromise/usePromise";
import { routes } from "../../../routes";
import CardCustom from "../../CardCustom/CardCustom";
import {
  toastify,
  capitalizeFirstLetter,
  processJobLocationTime,
} from "../../../helper/helper";
import InitialAvatar from "../../../components/InitialAvatar/InitialAvatar";
import JobsListingEffect from "../../effects/JobsListingEffect/JobsListingEffect";
import SavedPostsWidget from "../../SavedPostWidget/SavedPostWidget";
import { updateFavouriteJobsAPI } from "../../../services/JobServices";
import { useSavedJobs } from "../../../hooks/useSavedJobs";

const EmptySaveJobs = () => {
  return (
    <Grid item xs={12}>
      <div className="facelift-empty-boxwrap">
        <h3 className="header-primary">You haven't saved any jobs yet!</h3>
        <p>You can save jobs by clicking on the star.</p>
      </div>
    </Grid>
  );
};

const SaveJobList = ({ isWidget = false, backPage }) => {
  const navigate = useNavigate();
  const { users = {} } = useSelector((store) => store.userStore);
  const { id: userId } = users || {};
  const accessibilityHelp = localStorage.getItem("accessibilityHelp");

  const {
    savedJobs = [],
    refreshSavedJobs = () => {},
    savedJobsLoading,
  } = useSavedJobs();

  const [callingUpdateFavouriteJobsAPI, refreshUpdateFavouriteJobsAPI] =
    usePromise(updateFavouriteJobsAPI);

  useEffect(() => {
    if (
      callingUpdateFavouriteJobsAPI.hasFetched() &&
      callingUpdateFavouriteJobsAPI.hasErrors() === false &&
      callingUpdateFavouriteJobsAPI.data() &&
      callingUpdateFavouriteJobsAPI.data().message
    ) {
      toastify("success", callingUpdateFavouriteJobsAPI.data().message);
      refreshSavedJobs();
    }
  }, [callingUpdateFavouriteJobsAPI.isFetching()]);

  const updateFavourites = (params) => {
    refreshUpdateFavouriteJobsAPI(params);
  };

  const renderSaveJobList = (data, i) => {
    const {
      job_title = "",
      job_link = null,
      company_name = "",
      state = null,
      city = null,
      publish_date = null,
      is_favorite = true,
    } = data;
    return (
      <Grid item xs={12} key={i}>
        <CardCustom onClick={() => job_link !== null && window.open(job_link)}>
          <div className="save-item cursor-pointer">
            <div className="avtar">
              <InitialAvatar
                variant="rounded"
                title={company_name}
                index={i}
                initialCount={1}
              />
            </div>
            <div className="content">
              <div className="header-primary" id={`saved-job-title-${i}`}>
                {capitalizeFirstLetter(job_title)}
                {is_favorite ? (
                  <StarIcon
                    aria-label={`Remove ${job_title} from saved jobs`}
                    variant="filled"
                    onClick={(e) => {
                      updateFavourites({
                        ...data,
                        userId,
                        like: "unlike",
                      });
                      if (e) e.stopPropagation();
                    }}
                  />
                ) : (
                  <StarIcon
                    aria-label={`Add ${job_title} to saved jobs`}
                    onClick={() =>
                      updateFavourites({
                        ...data,
                        userId,
                        job_id: data.job_key,
                        like: "like",
                      })
                    }
                  />
                )}
              </div>
              <div className="sub-header-primary">{company_name}</div>
              <div className="text-grey">
                <span>{processJobLocationTime({ city, state })}</span>
                {((city !== null && city !== "null") ||
                  (state !== null && state !== "null")) &&
                  publish_date && (
                    <span className="left-right-space-span">|</span>
                  )}
                <span>{processJobLocationTime({ time: publish_date })}</span>
              </div>
            </div>
          </div>
        </CardCustom>
      </Grid>
    );
  };
  return (
    <>
      {isWidget ? (
        <SavedPostsWidget
          widgetText="Saved jobs"
          count={savedJobs.length}
          onClick={(e) => {
            e.preventDefault();
            if (savedJobs.length > 0) {
              navigate(routes.JOBS.SAVED_JOBS);
            }
          }}
          hideIfEmpty={!!(backPage === "jobs" || savedJobsLoading)}
          emptyTitle="Save jobs for later"
          emptySubTitle={`Keep track of jobs you're interested in. Select the star icon on a job to save it for later.`}
        />
      ) : (
        <SaveJobsListStyles arrowImg={ArrowImg}>
          <div className="save-list">
            <div className="job-title mb-15">
              <h2 className="title-primary" id={"saved-jobs-section-title"}>
                Saved jobs{" "}
                {savedJobs?.length > 0 ? `(${savedJobs.length})` : ""}
              </h2>
              {savedJobs && savedJobs.length > 0 && (
                <Link
                  to="/jobs/saved-jobs"
                  className="nlink-secondary view-all link-focus"
                  aria-describedby={`saved-jobs-section-title`}
                  aria-label={"View all saved jobs"}
                >
                  View all{accessibilityHelp == "true" ? " saved jobs" : ""}
                </Link>
              )}
            </div>
            {savedJobsLoading ? (
              <JobsListingEffect count={3} imageWidth={50} imageHeight={75} />
            ) : (
              <Grid container spacing={3}>
                {savedJobs && savedJobs.length > 0 ? (
                  savedJobs.map((data, i) => renderSaveJobList(data, i))
                ) : (
                  <EmptySaveJobs />
                )}
              </Grid>
            )}
          </div>
        </SaveJobsListStyles>
      )}
    </>
  );
};
export default SaveJobList;
