import { useReducer } from "react";
function reducer(state, action) {
  return { ...state, ...action };
}

const initialArgs = {
  profile: null,
  showProfilePicPopup: false,
  essentials: null,
  personalForm: null, // hold the local state of personal form
  educationsForm: null,
  experienceForm: null,
  careerForm: null,
  schools: null,
  getStarted: true,
  departmentList: {},
  isGoogleConnected: false, // State used for SSO first time login
  touchSections: {
    personal: 0,
    education: 0,
  },
  tab: "student",
};

const useProfile = () => {
  const [state, setState] = useReducer(reducer, initialArgs);

  const resetProfileState = () => {
    setState(initialArgs);
  };
  const updateProfileState = (payload) => {
    setState({
      ...payload,
    });
  };
  const setSchools = (schools) => {
    setState({
      schools,
    });
  };

  /**
   * set profile
   * @param {*} profile
   */
  const setProfile = (payload) => {
    setState({
      profile: {
        ...payload,
      },
    });
  };

  const setProfileInformation = (key, value) => {
    setState({
      profile: {
        ...state.profile,
        [key]: value,
      },
    });
  };

  const updateProfileInformation = (key, payload) => {
    setState({
      profile: {
        ...state.profile,
        [key]: {
          ...payload,
        },
      },
    });
  };
  const setEssentials = (essentials) => {
    setState({
      essentials,
    });
  };
  const setIsProfilePicPopup = (showProfilePicPopup) => {
    setState({
      showProfilePicPopup: !showProfilePicPopup,
    });
  };

  /**
   * Replace personal state
   * @param {*} personalForm
   */
  const setLocalPersonalForm = (personalForm) => {
    setState({
      personalForm: { ...personalForm },
    });
  };

  /**
   * update local personal form information
   * @param {*} key
   * @param {*} value
   */
  const setLocalPersonalFormInfo = (key, value) => {
    setState({
      isPersonalChanged: true,
      personalForm: {
        ...state.personalForm,
        [key]: value,
      },
    });
  };

  const setLocalPersonalFormInfoArray = (info) => {
    let data = { ...state.personalForm };
    info.map((i) => {
      data[i.key] = i.value;
    });
    setState({
      isPersonalChanged: true,
      personalForm: data,
    });
  };

  const setLocalPersonalFormMultiInfo = (changedData = {}) => {
    setState({
      isPersonalChanged: true,
      personalForm: {
        ...state.personalForm,
        ...changedData,
      },
    });
  };

  /**
   * To replace state
   * @param {*} items
   */
  const setLocalEducationForm = (items) => {
    setState({
      educationsForm: [...items],
    });
  };
  /**
   * Needed to update form state
   * @param {*} id
   * @param {*} key
   * @param {*} value
   * @param {*} isDrafted
   */
  const setLocalEducationFormInfo = (id, key, value, isDrafted = false) => {
    const _id = !isDrafted ? "id" : "draftId";
    let education_updated = [
      ...state?.educationsForm.map((item) => {
        return item[_id] === id
          ? {
              ...item,
              [key]: value,
            }
          : item;
      }),
    ];
    setState({
      isEducationChanged: true,
      educationsForm: education_updated,
    });
  };

  /**
   * Add a new education section
   * @param {*} item
   */
  const addEducation = (item) => {
    setState({
      educationsForm: [...(state?.educationsForm || []), item],
    });
  };

  /**
   * Remove education
   * @param {*} draftId
   */
  const removeEducation = (draftId) => {
    setState({
      educationsForm: state?.educationsForm.filter(
        (item) => (item.draftId && item.draftId !== draftId) || item?.id
      ),
    });
  };

  /**
   * Replace experience form state
   * @param {*} items
   */
  const setLocalExperienceForm = (items) => {
    setState({
      experienceForm: { ...items },
    });
  };

  /**
   * Update experience form state
   * @param {*} id
   * @param {*} key
   * @param {*} value
   * @param {*} isDrafted
   * @param {*} mapKey
   */
  const setLocalExperienceFormInfo = (
    id,
    key,
    value,
    isDrafted = false,
    mapKey
  ) => {
    const _id = !isDrafted ? "id" : "draftId";

    setState({
      isExperienceChanged: true,
      experienceForm: {
        ...state?.experienceForm,
        [mapKey]: state?.experienceForm[mapKey].map((item) =>
          item[_id] === id
            ? {
                ...item,
                [key]: value,
              }
            : item
        ),
      },
    });
  };

  /**
   * Add experience section
   * @param {*} key
   * @param {*} value
   */
  const addExperience = (key, value) => {
    setState({
      experienceForm: {
        ...state?.experienceForm,
        [key]: [...state.experienceForm[key], value],
      },
    });
  };

  /**
   * Remove experience section
   * @param {*} draftId
   * @param {*} key
   */
  const removeExperience = (draftId, key) => {
    setState({
      experienceForm: {
        ...state?.experienceForm,
        [key]: state?.experienceForm[key].filter(
          (item) => (item.draftId && item.draftId !== draftId) || item?.id
        ),
      },
    });
  };

  /**
   * Replace career state
   * @param {*} careerForm
   */
  const setLocalCareerForm = (careerForm) => {
    setState({
      careerForm: { ...careerForm },
    });
  };

  /**
   * Update career form state
   * @param {*} key
   * @param {*} value
   */
  const updateCareerForm = (key, value, isCareerUpdated = true) => {
    setState({
      isCareerUpdated,
      careerForm: {
        ...state?.careerForm,
        [key]: value,
      },
    });
  };

  /**
   * Update career form state
   * @param {*} key
   * @param {*} value
   */
  const updateBulkCareerForm = (payload) => {
    setState({
      isCareerUpdated: true,
      careerForm: {
        ...state.careerForm,
        ...payload,
      },
    });
  };

  const setDepartmentsList = (schoolId, departmentList) => {
    setState({
      departmentList: {
        ...state.departmentList,
        [schoolId]: [...departmentList],
      },
    });
  };

  const updateIsGoogleConnected = (status) => {
    setState({
      isGoogleConnected: status,
      personalForm: {
        ...state.personalForm,
        is_google_connected: status,
      },
    });
  };

  // Updates keys in personal form and profile.personal
  const updateLocalEditSecondaryEmail = (secondaryEmail, value) => {
    setState({
      isPersonalChanged: true,
      personalForm: {
        ...state.personalForm,
        secondary_email: secondaryEmail,
        is_secondary_email_verified: value,
      },
      profile: {
        ...state.profile,
        personal: {
          ...state.profile.personal,
          secondary_email: secondaryEmail,
          is_secondary_email_verified: value,
        },
      },
    });
  };

  const setTouchedSections = (payload) => {
    const _touchSections = { ...state.touchSections };

    (payload ?? []).forEach((key) => {
      _touchSections[key] = (_touchSections[key] ?? 0) + 1;
    });
    setState({
      touchSections: _touchSections,
    });
  };

  return {
    ...state,
    resetProfileState,
    updateProfileState,
    updateBulkCareerForm,
    setLocalPersonalFormInfo,
    setLocalPersonalFormInfoArray,
    setLocalPersonalFormMultiInfo,
    updateCareerForm,
    setLocalExperienceForm,
    setProfile,
    setEssentials,
    addEducation,
    setSchools,
    addExperience,
    setLocalEducationFormInfo,
    setLocalEducationForm,
    removeEducation,
    setLocalPersonalForm,
    setDepartmentsList,
    setIsProfilePicPopup,
    setLocalCareerForm,
    setLocalExperienceFormInfo,
    removeExperience,
    setProfileInformation,
    updateProfileInformation,
    updateIsGoogleConnected,
    updateLocalEditSecondaryEmail,
    setTouchedSections,
  };
};

export default useProfile;
