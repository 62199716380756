import styled from "styled-components";
import { PRIMARY_COLOR } from "../../styles/variables";

export default styled.div.attrs({ className: "" })`
  width: 100%;
  .info-panel {
    text-decoration: none;
    &:focus {
      .info-panel-card {
        box-shadow: 0 1px 14px 3px var(--gray-outline-color);
        .card-content {
          .panel-actions {
            opacity: 1;
            visibility: visible;
            transition: all 0.3s;
          }
          .panel-actions--discover {
            &__action,
            &__star {
              opacity: 1 !important;
              visibility: visible !important;
              transition: all 0.3s;
            }
          }
        }
      }
    }
  }
  .info-panel-card {
    padding: 15px 15px;
    margin-bottom: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    min-height: 100px;
    cursor: pointer;
    transition: all 0.3s;
    .card-content {
      .panel-header {
        .panel-title:first-child {
          flex-wrap: wrap;
          gap: 10px;
          row-gap: 6px;
          margin-bottom: 2px;
          > div {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
          }
        }
      }
    }
    &.cursor-default {
      cursor: default;
    }
    &:hover {
      box-shadow: 0 1px 14px 3px var(--gray-outline-color);
      .card-content {
        .panel-actions {
          opacity: 1;
          visibility: visible;
          transition: all 0.3s;
        }
        .panel-actions--discover {
          &__action,
          &__star {
            opacity: 1 !important;
            visibility: visible !important;
            transition: all 0.3s;
          }
        }
      }
    }
    .panel-actions--discover {
      &__action,
      &__star {
        @media (max-width: 1024px) {
          opacity: 1 !important;
          visibility: visible !important;
          transition: all 0.3s;
        }
      }
      @media (max-width: 767px) {
        justify-content: flex-start;
        margin-left: 60px !important;
        margin-top: 8px;
      }
    }
    &.panel-active {
      background: ${PRIMARY_COLOR};
      color: #fff;
      .card-content {
        .panel-header {
          .panel-title,
          .panel-sub-title,
          .panel-intro {
            color: #fff !important;
          }
          .panel-title {
            .tags {
              .tag-item {
                color: ${PRIMARY_COLOR};
              }
            }
          }
          .panel-intro {
            color: var(--medium-gray-color);
            display: flex;
            align-items: center;
            .description {
              color: #fff;
              font-family: "TTCommons-Regular";
              font-size: 15px;
              letter-spacing: 0;
              line-height: 17px;
              width: 100%;
              max-width: 270px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
        .panel-actions {
          opacity: 1;
          visibility: visible;
          transition: all 0.3s;
        }
        .panel-actions--discover {
          &__action,
          &__star {
            opacity: 1 !important;
            visibility: visible !important;
            transition: all 0.3s;
          }
        }
      }
    }

    .card-content {
      padding: 0;
      display: flex;
      align-items: center;
      width: 100%;

      .MuiAvatar-root {
        width: 70px;
        height: 70px;
        min-width: 70px;
        @media (max-width: 767px) {
          width: 50px;
          height: 50px;
          min-width: 50px;
        }
        ${(props) => props.avatarBackground && "background: #F1F4F7"};
        img {
          width: ${(props) => props.avatarWidth};
          height: ${(props) => props.avatarHeight};
        }
      }
      .panel-header {
        margin-left: 10px;
        width: calc(100% - 200px);
        padding-right: 10px;
        @media (max-width: 767px) {
          width: calc(100% - 60px);
        }
        .panel-title {
          color: var(--primary-dark-color);
          display: flex;
          align-items: center;
          font-family: "TTCommons-Regular", "Apple Color Emoji",
            "NotoColorEmoji-Regular";
          font-size: 18px;
          font-weight: 500;
          line-height: 20px;
          padding: 4px 0 0 0;

          .star {
            margin: -5px 0 0 7px;
          }

          .notify-container {
            min-width: 30px;
            span.notify {
              height: 25px;
              width: 25px;
              border-radius: 12.5px;
              background: var(--secondary-color);
              color: #fff;
              font-family: "TTCommons-Regular";
              font-size: 15px;
              letter-spacing: 0;
              line-height: 17px;
              text-align: center;
              display: flex;
              align-items: center;
              justify-content: center;
              padding-top: 3px;
              margin-left: auto;
            }
          }

          .tag-item-wrapper {
            background: #ffffff;
            border-radius: 12.5px;
          }
          .tag-item {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 12.5px;
            background-color: #f1f4f7;
            min-width: 69px;
            height: 25px;
            padding-inline: 8px;
            img {
              margin-right: 4.5px;
            }
            span {
              font-family: "TTCommons-Regular";
              line-height: 21px;
              font-size: 15px;
              text-align: center;
              white-space: nowrap;
              color: var(--primary-dark-color);
              text-transform: capitalize;
              height: 25px;
              line-height: 27px;
            }
          }
          + .panel-title {
            padding-top: 0;
          }
          .tag-item--blue {
            background: var(--link-color) !important;
            span {
              color: #ffffff !important;
            }
          }
          .tag-item--mentor {
            background: #335cd4 !important;
            span {
              color: #ffffff !important;
            }
          }
          .tag-item--alumni,
          .tag-item--student {
            background: #d7ddf0 !important;
            span {
              color: var(--primary-dark-color) !important;
            }
          }
          .tag-item--community-creator,
          .tag-item--external {
            background: var(--icon_background) !important;
            span {
              color: var(--primary-dark-color) !important;
            }
          }
          .tag-item--admissions {
            background: rgba(247, 100, 95, 0.09) !important;
            span {
              color: #a80c06 !important;
            }
          }
        }
        .panel-sub-title {
          color: var(--primary-dark-color);
          font-family: "TTCommons-Regular";
          font-size: 15px;
          line-height: 15px;
          display: block;
          width: 100%;
          word-break: break-word;
          padding-top: 4px;
          margin: 0 0 6px 0;
        }
        .panel-intro {
          color: var(--primary-dark-color);
          font-size: 15px;
          line-height: 15px;
          display: flex;
          align-items: center;
          font-family: "TTCommons-Regular";
          .description {
            color: var(--primary-dark-color);
            font-family: "TTCommons-Regular";
            font-size: 15px;
            letter-spacing: 0;
            line-height: 17px;
            width: 100%;
            max-width: 270px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .c-flag {
            height: 15px;
            width: 22px;
            border-radius: 2px;
            background-color: #d8d8d8;
            display: flex;
            align-items: center;
            overflow: hidden;
            margin: -4px 0 0 0;
            box-shadow: 0 1px 4px 0 rgba(209, 221, 229, 0.4);
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
            }
          }
        }
      }

      .panel-actions {
        margin-left: auto;
        display: flex;
        align-items: center;
        opacity: 0;
        visibility: hidden;
        @media (max-width: 1024px) {
          opacity: 1;
          visibility: visible;
        }
        @media (max-width: 767px) {
          margin-left: 60px;
        }
        &.show-actions {
          opacity: 1;
          visibility: visible;
        }
        button {
          margin: 0;
          padding: 8px 8px 3px;
          font-size: 18px;
          font-weight: 500;
          line-height: 24px;
          min-height: 32px;
          text-align: center;
          height: 32px;
          min-width: 101px;
          border-radius: 100px;
          .content {
            img {
              vertical-align: middle;
              margin: -2px 0 0 8px;
            }
          }
          + button,
          + div {
            margin-left: 12px;
          }
          &.btn-unfollow {
            background: var(--gray-text-color);
            color: #fff;
          }
        }
        div {
          + div {
            margin-left: 15px;
          }
        }
        &--discover {
          margin-left: auto;
          display: flex;
          align-items: center;
          &__action {
            opacity: 0;
            visibility: hidden;
            button {
              height: 32px;
              border-radius: 100px;
              padding-top: 4px;
              img {
                margin: -4px 0 0 6px;
                vertical-align: middle;
              }
            }
          }
          &__addedAction {
            opacity: 1;
            visibility: visible;
            transition: all 0.3s;
            button {
              height: 32px;
              border-radius: 100px;
              padding-top: 4px;
              img {
                margin: -4px 0 0 6px;
                vertical-align: middle;
              }
            }
          }
          &__star {
            margin-left: 12px;
            opacity: 0;
            visibility: hidden;
            transition: all 0.3s;
            &--active {
              opacity: 1;
              visibility: visible;
              transition: all 0.3s;
            }
          }
          &__showActions {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }
  .header-custom-width {
    width: 65% !important;
  }
`;
