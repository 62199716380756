import styled from "styled-components";
import { PRIMARY_COLOR } from "../../../../styles/variables";

export default styled.div.attrs({ className: "" })`
  .h-information {
    text-align: center;
    > div {
      + div {
        margin-top: 8px;
      }
    }
    .h-information--logo {
      display: inline-block;
      .MuiAvatar-root {
        width: 50px;
        height: 50px;
        padding: 0px;
        margin: 0 10px 0 0;
        border-radius: 100px;
        padding-top: 8px;
      }
      .company-logo {
        width: 50px;
        height: 50px;
        padding: 0px;
        border-radius: 100px;

        img {
          height: auto;
          object-fit: initial;
        }
      }
    } 
    .header-primary {
      font-size: 15px;
      letter-spacing: 0;
      line-height: 17px;
      text-align: center;
      font-family: "TTCommons-Regular";
    }
    .sub-header-primary {
      font-size: 15px;
      letter-spacing: 0;
      line-height: 15px;
      text-align: center;
      color: ${PRIMARY_COLOR};
      margin: 0 0 5px 0;
    }
    .text-grey {
      font-size: 15px;
      letter-spacing: 0;
      line-height: 17px;
      text-align: center;
      color: var(--field-label-color);
    }
    .services {
      margin: 0px 0;
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
          font-size: 15px;
          letter-spacing: 0;
          line-height: 17px;
          text-align: center;
          color: var(--field-label-color);
          font-family: "TTCommons-Regular";
        }
      }
    }
    .underline-a {
      text-decoration: none;
      .text-secondary {
        display: inline-block;
        border-bottom: 1px solid var(--secondary-color);
      }
    }
    .social-icons {
      margin-top: 20px;
      ul {
        margin: 0;
        list-style: none;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        li {
          width: 25%;
          margin: 0px 0 4px 0
          text-align: center;
          .MuiAvatar-root {
            width: 45px;
            height: 45px;
            margin: 0 auto 2px;
            box-shadow: 0 1px 11px 2px rgb(209 221 229 / 40%);
            padding: 0;
          }
          span {
            font-size: 15px;
            letter-spacing: 0;
            line-height: 17px;
            text-align: center;
            display: block;
            color: var(--primary-dark-color);
            font-family: "TTCommons-Regular";
            margin: 4px 0 0 0;
            white-space: nowrap;
            display: inline-block;
          }
        }
      }
    }
  }
`;
