import React from "react";
import { Link, useNavigate } from "react-router-dom";

import OnboardingHeaderStyles from "./OnboardingHeaderStyles";

import BackArrow from "../../../../assets/svg/f-btn-arrow-gray.svg";
import { INTERSTRIDE_DEFAULT_LOGO } from "../../../../helper/helper";

const OnboardingHeader = ({
  title = "",
  title2 = "",
  subtitle = "",
  navigateBackUrl = null,
}) => {
  const navigate = useNavigate();

  return (
    <OnboardingHeaderStyles>
      <div className="onboarding-header">
        {navigateBackUrl && (
          <Link to={navigateBackUrl} className="back-to-url link-focus">
            <img src={BackArrow} id="BackArrow" width="18px" alt="Back arrow" />
            <span>Back</span>
          </Link>
        )}
        <div className="onboarding-sub-header">
          <div className="logo">
            <img
              src={INTERSTRIDE_DEFAULT_LOGO}
              alt="interstride logo"
              onClick={() => navigate("/")}
            />
          </div>
        </div>
        <h1 className="title text-primary">{title}</h1>
        {title2 && <h2 className="title text-primary">{title2}</h2>}
        {subtitle && <h2 className="subtitle text-primary">{subtitle}</h2>}
      </div>
    </OnboardingHeaderStyles>
  );
};

export default OnboardingHeader;
