import React, {
  useEffect,
  Suspense,
  useState,
  lazy,
  useCallback,
  useRef,
} from "react";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";

import { StyledEngineProvider } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import { StylesProvider } from "@mui/styles";
import { ToastContainer } from "react-toastify";
import without from "underscore/modules/without";
import moment from "moment";
import { LinkedInPopUp } from "react-linkedin-login-oauth2";
import TagManager from "react-gtm-module";
import Bugsnag from "@bugsnag/js";

import "./App.css";
import "./styles/main.scss";
import "./constant/firebase";

import { theme } from "./theme/theme";
import { AppStateProvider } from "./context";
import { BASE_PATH, routes } from "./routes";
import {
  camelCase,
  updateLastActiveSession,
  applyAccessibilityHelp,
  addMicrosoftClarity,
  INTERSTRIDE_DEFAULT_LOGO,
  clearLocalStorage,
  toastify,
} from "./helper/helper";
import usePromise from "./hooks/usePromise/usePromise";
import { getUserPermissionsAPI } from "./services/authenticationServices";
import WebsocketProvider from "./WebsocketProvider";
import { config } from "./constant/config";
import TutorialAmbassador from "./helper/tutorialAmbassador";
import {
  cacheTimezone,
  fetchRouteParamsFromURL,
  getRedirectRouteFromURL,
  isPageAccessible,
} from "./utils/common";

/** App State */
import usePosts from "./state/usePosts";
import useApp from "./state/useApp";
import useBoostProfile from "./state/useBoostProfile";
import useMode from "./state/useMode";
import useAuth from "./state/useAuth";
import useCountryInsight from "./state/useCountryInsight";
import useNotification from "./state/useNotification";
import useJob from "./state/useJobs";
import useNetwork from "./state/useNetwork";
import useTopicV2 from "./state/useTopicV2";
import useTools from "./state/useTools";
import useLearn from "./state/useLearn";
import useAmbassadorTutorial from "./state/useAmbassadorTutorial";

/** App Pages Without Lazy Loading */
import Dashboard from "./pages/Dashboard/Dashboard";
import Notification from "./pages/Notification/Notification";
import Jobs from "./pages/JobPortal/Jobs";
import Learn from "./pages/Learn/Learn";
import Network from "./pages/Network/Network";
import Tools from "./pages/Tools/Tools";
import SavedPost from "./pages/SavedPost/SavedPost";
import BoostProfile from "./pages/BoostProfile/BoostProfile";
import HelpCenter from "./pages/HelpCenter/HelpCenter";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions/TermsAndConditions";
import useChat from "./state/useChat";
import { ViewportProvider } from "./context/viewportContext";
import KommunicateChat from "./components/ChatBot/ChatBot";
import ProfileModule from "./pages/ProfileModule";
import useProfile from "./state/useProfile";
import useAmbassadorProfile from "./state/useAmbassadorProfile";
import AmbassadorAcceptInvite from "./pages/Ambssador/AmbassadorAcceptInvite";
import VerifySecondaryEmail from "./components/VerifyEmail/VerifySecondaryEmail";
import Smartbanner from "./components/Smartbanner/Smartbanner";
import Widget from "./pages/Widget/Widget";
import { AppVersionManager } from "./components/AppVersionManager/AppVersionManager";
import ParentEffect from "./components/atoms/ParentEffect/ParentEffect";
import {
  setSavedRoute,
  wipeOutSavedRoute,
} from "./redux/reducers/preservedReducer";
// import GoogleTranslate from "./components/GoogleTranslate/GoogleTranslate";
import {
  updateUserPermissions,
  updatePastOrUpcomingWebinarsAvailable,
} from "./redux/reducers/userReducer";
import { getWebinarsAPI } from "./services/webinarServices";

const Login = lazy(() => import("./pages/Onboarding/Login/Login"));
const Onboarding = lazy(() => import("./pages/Onboarding/Onboarding"));
const UnsubscribeNotifications = lazy(() =>
  import("./pages/Notification/Unsubscribe/Unsubscribe")
);
const EditEmail = lazy(() => import("./pages/EditEmail/EditEmail"));

const containers = {
  post: usePosts,
  auth: useAuth,
  app: useApp,
  boostProfile: useBoostProfile,
  modeManger: useMode,
  tools: useTools,
  learn: useLearn,
  job: useJob,
  countryInsight: useCountryInsight,
  notifications: useNotification,
  network: useNetwork,
  topic: useTopicV2,
  chat: useChat,
  profile: useProfile,
  ambassadorTutorial: useAmbassadorTutorial,
  ambassadorProfile: useAmbassadorProfile,
};

var requestFirebaseNotificationPermission = null;
var isSafari =
  /constructor/i.test(window.HTMLElement) ||
  (function (p) {
    return p.toString() === "[object SafariRemoteNotification]";
  })(
    !window["safari"] ||
      (typeof safari !== "undefined" && window["safari"].pushNotification)
  );

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loginRef = useRef(null);
  const [loggedIn, setloggedIn] = useState(false);
  const [callGetUserPermissionsAPI, refreshGetUserPermissionsAPI] = usePromise(
    getUserPermissionsAPI
  );
  const { savedRoute = {} } = useSelector((store) => store.preservedStore);
  const { permissions = {}, studentViewPreference = "international" } =
    useSelector((store) => store.userStore);

  useEffect(() => {
    const accessibilityHelp = localStorage.getItem("accessibilityHelp");
    const authToken = localStorage.getItem("authToken");
    const email = localStorage.getItem("email");
    const userId = localStorage.getItem("userId");
    const keepSignedIn = localStorage.getItem("keepSignedIn");
    const isLogin = sessionStorage.getItem("isLogin");
    const authExpire = localStorage.getItem("authTokenExpiration");
    const authExpiration = moment(authExpire).unix();
    const currDate = moment().add(5, "minutes").unix();

    applyAccessibilityHelp(isLogin === "1" && accessibilityHelp === "true");

    if (
      userId &&
      email &&
      authToken &&
      (isLogin === "1" || (keepSignedIn === "1" && currDate <= authExpiration))
    ) {
      refreshGetUserPermissionsAPI();
      loginRef.current = true;
      setloggedIn(true);

      // Leave breadcrumb
      Bugsnag.leaveBreadcrumb(
        "Login state change",
        {
          prevState: loggedIn,
          login: true,
          instance: "On load",
        },
        "state"
      );

      if (!isLogin) sessionStorage.setItem("isLogin", "1");
      if (
        window.location.pathname === "/" ||
        window.location.pathname === "/login"
      ) {
        navigate(routes.DASHBOARD);
      }
    } else if (isLogin === "1" && (!authToken || !authExpire)) {
      // Cleanup leftout session/localstorage
      clearLocalStorage();
    }

    window.addEventListener("storage", (event) => {
      // handle case where localstorage is cleared somehow but sessionstorage isn't
      if (
        ["authToken", "authTokenExpiration"].includes(event.key) &&
        !event.newValue
      ) {
        if (sessionStorage.getItem("isLogin") === "1") {
          // Leave breadcrumb
          Bugsnag.leaveBreadcrumb(
            "Localstorage wipe out",
            {
              key: event.key,
              newValue: event.newValue,
              oldValue: event.oldValue,
            },
            "state"
          );

          // wipe out all data for proper logout
          clearLocalStorage();
          navigate(routes.LOGIN);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // globalHistory.listen function related changes are integrated in below useEffect
  // reference for similar solution: https://github.com/remix-run/remix/discussions/5999
  useEffect(() => {
    // Leave breadcrumb
    Bugsnag.leaveBreadcrumb(
      "Location change",
      {
        location,
      },
      "navigation"
    );

    const isLogin = sessionStorage.getItem("isLogin");

    if (loggedIn !== (isLogin === "1"))
      // Leave breadcrumb
      Bugsnag.leaveBreadcrumb(
        "Login state change",
        {
          prevState: loggedIn,
          login: isLogin === "1",
          instance: "On location change",
          location,
        },
        "state"
      );

    setloggedIn(isLogin === "1");

    setTitle(location.pathname);
    window.scrollTo(0, 0); //To solve the issue of scrolling while navigating - TBS

    // On url change, update last active session via API call
    updateLastActiveSession(3600000); // time in milliseconds
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // if the user is logged in, timezones list will be fetched once per session
  useEffect(() => {
    const timezones = sessionStorage.getItem("get_rails_timezone");
    if (
      loggedIn &&
      (!timezones || timezones === "undefined" || timezones === "null")
    ) {
      cacheTimezone();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn]);

  // This Effect tracks permissions and login state
  // If webinars permission is true and user is logged in,
  // We will call webinars API to check if it has data and update state
  // This will help up to hide webinars submenu if there are no upcoming and past webinars
  useEffect(() => {
    if (permissions?.webinar && loggedIn) {
      (async () => {
        const response = await getWebinarsAPI();
        if (response.success && response.data) {
          dispatch(
            updatePastOrUpcomingWebinarsAvailable(
              response?.data?.past_webinars?.length > 0 ||
                response?.data?.upcoming_webinars?.length > 0
            )
          );
        }
      })();
    }
  }, [permissions?.webinar, loggedIn, dispatch]);

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: config().GOOGLE_TAG_MANAGER_ID,
    };

    TagManager.initialize(tagManagerArgs);
  }, []);

  useEffect(() => {
    if (
      callGetUserPermissionsAPI.hasFetched() &&
      callGetUserPermissionsAPI.hasErrors() === false
    ) {
      if (callGetUserPermissionsAPI.data()) {
        dispatch(updateUserPermissions(callGetUserPermissionsAPI.data()));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callGetUserPermissionsAPI.isFetching()]);

  useEffect(() => {
    // Integration of Microsoft Clarity - Dhaval's task
    if (process.env.REACT_APP_ENV !== "stage") addMicrosoftClarity();

    if (!isSafari && window.location.protocol === "https:") {
      import("./constant/firebase").then((something) => {
        requestFirebaseNotificationPermission =
          something.requestFirebaseNotificationPermission;
        setTimeout(() => {
          RequestFirebaseNotification();
        }, 3000);
      });
    }
  }, []);

  const RequestFirebaseNotification = () => {
    requestFirebaseNotificationPermission()
      .then((firebaseToken) => {
        localStorage.setItem("firebaseToken", firebaseToken);
      })
      .catch((err) => {
        console.log("erroroor", err);
        return err;
      });
  };

  const setTitle = (path) => {
    let title = "Interstride";
    if (path) {
      let existData = path.split("/");
      if (existData && existData.length > 1) {
        const pathArray = without(existData, "");
        const titleName = pathArray[pathArray.length - 1];
        title = camelCase(
          titleName && titleName.length > 0 && titleName !== "noninvite"
            ? titleName === "forgotpassword"
              ? "Forgot-Password"
              : titleName === "resetpassword"
              ? "Reset-Password"
              : titleName
            : "Interstride"
        );
      }
    }

    document.title = title !== "Interstride" ? title + " | Interstride" : title;
  };

  useEffect(() => {
    const handleRejection = (e) => {
      console.warn(`UNHANDLED PROMISE REJECTION: ${e.reason}`);
      e.preventDefault();
    };
    window.addEventListener("unhandledrejection", handleRejection);
    return () =>
      window.removeEventListener("unhandledrejection", handleRejection);
  }, []);

  const fallBackUI = (
    <div className="page-loader">
      <div className="">
        <img src={INTERSTRIDE_DEFAULT_LOGO} alt="interstride logo" />
        <div className="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );

  /**
   * Important effect : 2
   * This effect will run after school permissions are set upon login
   * This effect will then check if redirectPath / page is permissible to access by user
   * This effect will then redirect to dashboard / saved route as per permission
   */
  useEffect(() => {
    if ((loginRef.current || loggedIn) && !isEmpty(permissions)) {
      const redirectPath = getRedirectRouteFromURL();
      if (isPageAccessible(permissions, redirectPath)) {
        // Check request page permission
        if (savedRoute && !isEmpty(savedRoute)) {
          // Need to wipe out saved route once user is logged in and route is used once for redirection
          dispatch(wipeOutSavedRoute());
          // redirectPath was already taken from savedRoute in getRedirectRouteFromURL Function
          if (savedRoute?.route_path !== routes.ONBOARDING.RESET_PASSWORD) {
            navigate(redirectPath, { replace: true });
          }
        }
      } else {
        navigate(routes.DASHBOARD, { replace: true });
        toastify(
          "You do not have the necessary permissions to access this page."
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions]);

  const initialEffect = useCallback(() => {
    // Get and save route params if present to be used after signup/login
    (() => {
      const routeParams = fetchRouteParamsFromURL();
      if (routeParams && !isEmpty(routeParams)) {
        dispatch(setSavedRoute(routeParams));
      }
    })();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {/* <GoogleTranslate /> */}
      {/* We need to save initial app load route for further redirection after login/signup, before any of the child components performs any redirection*/}
      <ParentEffect effect={initialEffect} />
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <StylesProvider injectFirst>
            <AppVersionManager />
            <AppStateProvider containers={containers}>
              <ViewportProvider>
                <Suspense fallback={fallBackUI}>
                  <Routes>
                    <Route
                      path={BASE_PATH}
                      element={
                        <Login
                          RequestFirebaseNotification={
                            window.location.protocol === "https:" &&
                            RequestFirebaseNotification
                          }
                          loggedIn={loggedIn}
                          loginRef={loginRef}
                        />
                      }
                    />
                    <Route
                      path={routes.LOGIN}
                      element={
                        <Login
                          RequestFirebaseNotification={
                            window.location.protocol === "https:" &&
                            RequestFirebaseNotification
                          }
                          loggedIn={loggedIn}
                          loginRef={loginRef}
                        />
                      }
                    />
                    <Route
                      path={routes.ONBOARDING.PATH}
                      element={<Onboarding />}
                    />
                    <Route
                      path={routes.VERIFY_SECONDARY_EMAIL}
                      element={<VerifySecondaryEmail />}
                    />
                    <Route exact path="/linkedin" element={<LinkedInPopUp />} />
                    <Route path={routes.EDIT_EMAIL} element={<EditEmail />} />
                    <Route
                      path={routes.AMBASSADOR_ACCEPT_INVITE}
                      element={<AmbassadorAcceptInvite />}
                    />
                    <Route
                      path={routes.NOTIFICATION.UNSUBSCRIBE}
                      element={<UnsubscribeNotifications />}
                    />
                    <Route path={routes.WIDGET} element={<Widget />} />
                    {loggedIn && (
                      <>
                        <Route
                          path={routes.DASHBOARD}
                          element={<Dashboard />}
                        />
                        <Route
                          path={routes.SAVED_POSTS}
                          element={<SavedPost />}
                        />
                        <Route
                          path={routes.BOOST_PROFILE}
                          element={<BoostProfile />}
                        />
                        <Route
                          path={routes.JOBS.PATH}
                          element={
                            <Jobs
                              permissions={permissions}
                              studentViewPreference={studentViewPreference}
                            />
                          }
                        />
                        <Route
                          path={routes.LEARN.PATH}
                          element={<Learn permissions={permissions} />}
                        />
                        <Route
                          path={routes.NETWORK.PATH}
                          element={<Network permissions={permissions} />}
                        />
                        <Route
                          path={routes.TOOLS.PATH}
                          element={<Tools permissions={permissions} />}
                        />
                        <Route
                          path={routes.PROFILE.PATH}
                          element={<ProfileModule />}
                        />
                        <Route
                          path={routes.NOTIFICATION.PATH}
                          element={<Notification />}
                        />
                        <Route
                          path={routes.HELP.PATH}
                          element={<HelpCenter />}
                        />
                        <Route
                          path={routes.PRIVACY_POLICY}
                          element={<PrivacyPolicy />}
                        />
                        <Route
                          path={routes.TERMS_AND_CONDITIONS}
                          element={<TermsAndConditions />}
                        />
                      </>
                    )}
                    <Route
                      path="*"
                      element={
                        <Navigate
                          to={loginRef.current ? routes.DASHBOARD : BASE_PATH}
                        />
                      }
                    />
                  </Routes>
                  <ToastContainer
                    autoClose={5000}
                    style={{ width: "max-content" }}
                  />
                  <TutorialAmbassador />
                  <WebsocketProvider loggedIn={loggedIn} />
                  <Smartbanner />
                </Suspense>
              </ViewportProvider>
            </AppStateProvider>
          </StylesProvider>
        </ThemeProvider>
      </StyledEngineProvider>
      {/* Chatbot should not appear on career widget */}
      {loggedIn &&
        !(window.location.pathname ?? "").includes("/university") && (
          <KommunicateChat />
        )}
    </>
  );
}

export default App;
