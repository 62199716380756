import styled from "styled-components";
import {
  PRIMARY_COLOR,
  INACTIVE_BTN_GRAY_COLOR,
} from "../../../styles/variables";

export default styled.div.attrs({ className: "" })`
  .top-featured-jobs-list {
    margin: 0 0 40px 0;
    .title-primary {
      display: flex;
      align-items: center;
      @media (max-width: 480px) {
        justify-content: center;
      }
      a {
        @media (max-width: 480px) {
          margin-right: 0 !important;
        }
      }
    }

    .title-boxs {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      align-items: flex-end;
    }
    .search-top {
      display: flex;
      flex-wrap: no-wrap;
      justify-content: space-between;
      margin-bottom: 12px;
      .sort-by {
        span.sortby {
          font-size: 15px;
          letter-spacing: 0;
          line-height: 17px;
        }
        .react-custom-dropdown {
          color: ${PRIMARY_COLOR};
          font-size: 15px;
          letter-spacing: 0;
          line-height: 17px;
          > div:nth-child(3) {
            margin: 0 !important;
            > div {
              margin: 0 !important;
              > div {
                font-size: 15px;
                letter-spacing: 0;
                line-height: 17px;
                margin: 0 !important;
                overflow: visible !important;
                > div {
                  font-size: 15px;
                  letter-spacing: 0;
                  line-height: 17px;
                  margin: 0 !important;
                  > &:hover {
                    background: ${PRIMARY_COLOR};
                    color: #fff;
                  }
                }
              }
            }
          }
        }
      }
    }
    .search-item-tags {
      display: flex;
      gap: 5px;
      flex-wrap: wrap;
      row-gap: 10px;
      margin-bottom: 10px;
      .tag-item {
        border-radius: 100px;
        background-color: #f1f4f7;
        color: #000c3d;
        font-family: "TTCommons-Regular";
        font-size: 15px;
        line-height: 21px;
        text-align: center;
        padding: 4px 10px 0;
        display: inline-block;
        white-space: nowrap;
        @media (max-width: 600px) {
          margin: 0 8px 4px 0;
        }
      }
    }
    .search-item {
      display: flex;
      flex-wrap: no-wrap;
      align-items: center;
      @media (max-width: 600px) {
        align-items: flex-start;
      }
      &.featured-job {
        .content {
          .header-primary {
            align-items: center;
          }
        }
      }
      .avtar {
        margin-right: 15px;
      }
      .MuiAvatar-root {
        width: 50px;
        height: 50px;
        border-radius: 100px;
      }
      .card-actions-wrapper {
        display: flex;
        align-items: center;
        opacity: 0;
        visibility: hidden;
        &.show-actions {
          opacity: 1;
          visibility: visible;
        }
        @media (max-width: 1024px) {
          opacity: 1;
          visibility: visible;
        }
        .nlink-btn {
          width: auto;
          min-width: 80px;
          white-space: nowrap;
        }
        div {
          + div {
            margin-left: 12px;
            @media (max-width: 991px) {
              margin-left: 8px;
            }
          }
        }
        .bg-gray {
          color: #ffffff;
          background: var(--gray-color);
          &:hover {
            background: var(--gray-color-btn-hover);
          }
          &:disabled {
            background: ${INACTIVE_BTN_GRAY_COLOR};
            color: #ffff;
          }
        }
      }
      .content {
        margin-right: auto;
        max-width: calc(100% - 170px);
        @media (max-width: 600px) {
          max-width: 100%;
        }
        .header-primary {
          display: flex;
          align-items: flex-start;
          align-items: center;
          padding-top: 4px;
          line-height: 16px;
          @media (max-width: 600px) {
            flex-wrap: wrap;
            line-height: normal;
          }
          span {
            display: inline-block;
            white-space: normal;
            word-break: break-word;
            &:first-child {
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              @media (max-width: 600px) {
                width: 100%;
                display: block;
                white-space: normal;
              }
            }
          }
          .star {
            margin: -3px 0 0 10px;
          }
        }
        .sub-header-primary {
          margin-bottom: -1px;
        }
        .text-grey {
          padding-top: 4px;
          line-height: 16px;
        }
      }
      .apply-btn {
        button {
          border-radius: 45px;
          background-color: var(--secondary-color);
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          -webkit-align-items: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          width: 74px;
          line-height: 30px;
          height: auto;
          padding: 0;
          box-shadow: none;
          font-family: "TTCommons-Regular";
          a {
            text-decoration: none;
            color: #fff;
            font-family: "TTCommons-Regular";
          }
        }
      }
    }
    .card-wrapper {
      .MuiPaper-root {
        padding: 18px 15px !important;
        transition: all 0.3s;
        min-height: 100px;
        display: flex;
        align-items: center;
        cursor: pointer;
        @media (max-width: 767px) {
          min-height: auto;
        }
        &:hover {
          box-shadow: 0 1px 14px 3px var(--gray-outline-color);
          transition: all 0.3s;
          .search-item {
            .card-actions-wrapper {
              opacity: 1;
              visibility: visible;
              transition: all 0.3s;
            }
          }
        }
        @media (max-width: 991px) {
          padding: 16px 16px !important;
        }
        .MuiCardContent-root {
          padding: 0;
          width: 100%;
        }
      }
    }
    .MuiGrid-item {
      + .MuiGrid-item {
        margin: 15px 0 0 0;
      }
    }
    .custom-rss-icon {
      height: 15px;
      width: 15px;
      position: absolute;
      margin-top: 5px;
      margin-left: 5px;
    }
  }
`;
