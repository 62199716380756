import React, { Fragment, memo, useMemo } from "react";

import { useAppState } from "../../../context";
import { uniqueId } from "../../../utils/common";
import { LineDivider } from "../Divider/Divider";
import { EducationItem } from "./EducationItem/EducationItem";
import { SectionFooter } from "../SectionFooter/SectionFooter";
import { toastify } from "../../../helper/helper";

const MAX_EDUCATIONS_NUMBER = 4; // Limit of how many educations a student can have

//Main thread
const Educations = () => {
  const {
    educationsForm,
    addEducation,
    removeEducation,
    setLocalEducationFormInfo,
    touchSections,
    departmentList,
    profile,
  } = useAppState("profile");
  const { educations } = profile || {};
  const educationIds = useMemo(() => {
    if (educations) return educations?.map((item) => item.id);
    return [];
  }, [educations]);

  const handleClick = (flag, educationId) => {
    if (!flag) {
      // Check education limit
      if (educationsForm.length > MAX_EDUCATIONS_NUMBER - 1) {
        toastify(
          "error",
          "You can only add maximum of 4 educations in your profile."
        );
        return;
      }
      //add education
      const draftId = uniqueId();
      addEducation({ draftId });
      return;
    }
    const draft = educationIds.includes(educationId);
    if (!draft) {
      removeEducation(educationId);
      return;
    }
    setLocalEducationFormInfo(educationId, "remove", true);
  };

  return (
    <Fragment>
      {educationsForm && (
        <Fragment>
          {educationsForm
            .filter((item) => !item?.remove)
            .map((item, index) => {
              return (
                <Fragment
                  key={`education-item-id-${item?.id || item?.draftId}`}
                >
                  <EducationItem
                    index={index}
                    education={item}
                    touchSections={touchSections}
                    departmentList={departmentList}
                    checkDepartment={index > 0 ? false : true}
                  />
                  {index > 0 && (
                    <SectionFooter
                      isRemove
                      label={"Remove education"}
                      handleClick={(flag) =>
                        handleClick(flag, item.draftId ?? item?.id)
                      }
                    />
                  )}
                  <LineDivider />
                </Fragment>
              );
            })}
        </Fragment>
      )}
      <SectionFooter
        handleClick={handleClick}
        label={"Add another education"}
      />
    </Fragment>
  );
};

export default memo(Educations);
