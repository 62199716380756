import React, { useState, useEffect } from "react";
import { ShimmerPostDetails } from "react-shimmer-effects";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  getJobDetails,
  updateFavouriteJobsAPI,
} from "../../../services/JobServices";
import usePromise from "../../../hooks/usePromise/usePromise";
import EmptyListWithImage from "../../EmptyListWithImage/EmptyListWithImage";
import styles from "./JobDetails.module.scss";
import { createModuleStyleExtractor } from "../../../utils/css";
import ButtonCustom from "../../ButtonCustom/ButtonCustom";
import { routes } from "../../../routes";
import StarIcon from "../../StarIcon/StarIcon";
import { useSavedJobs } from "../../../hooks/useSavedJobs";
import {
  toastify,
  parseEmploymentType,
  parseEmploymentExperienceLevel,
  parseDegree,
  capitalizeFirstLetter,
  numberWithCommas,
  parseWorkType,
  renderTimestamp,
} from "../../../helper/helper";

import BackArrow from "../../../assets/svg/f-btn-arrow-gray.svg";
import InterstrideAiLogo from "../../../assets/svg/interstride-ai-logo.svg";
import { STUDENT_VIEW_PREFERENCES_OPTIONS } from "../../../utils/common";

const cx = createModuleStyleExtractor(styles);

export default function JobsDetails({
  windowTitle = "Jobs | Interstride",
  emptyCardTitle = "Looks like there are no jobs with this search.",
}) {
  // Assign Page title
  window.document.title = windowTitle;

  // Hooks
  const { users = {} } = useSelector((store) => store.userStore);
  const { savedJobs = [], refreshSavedJobs = () => {} } = useSavedJobs();
  const params = useParams();
  const navigate = useNavigate();

  const [callingGetJobDetails, refreshGetJobDetails] =
    usePromise(getJobDetails);
  const [callingUpdateFavouriteJobsAPI, refreshUpdateFavouriteJobsAPI] =
    usePromise(updateFavouriteJobsAPI);

  // States - Local
  const [jobData, setJobData] = useState({});

  // Variables and Constants
  const favJobKeyList = savedJobs.map(({ job_id }) => job_id);
  const { id: userId } = users || {};

  // Methods
  const handleSaveUnsaveJob = () => {
    refreshUpdateFavouriteJobsAPI({
      ...jobData,
      userId,
      job_id: jobData.job_key,
      like: favJobKeyList.includes(jobData?.job_key) ? "unlike" : "like",
    });
  };

  const openCompany = () => {
    if (jobData.no_of_petitions > 0 && jobData?.company) {
      // Open U.S. Visa insight company details page in new window
      window.open(
        `${routes.JOBS.COMPANY_DETAILS}?${
          jobData.company_id
            ? `company_id=${
                jobData.company_id
              }&employer_name=${encodeURIComponent(jobData.company)}`
            : `employer_name=${encodeURIComponent(jobData.company)}`
        }`
      );
    }
  };

  // Effects
  useEffect(() => {
    params?.id && refreshGetJobDetails(params.id);
  }, [params?.id, refreshGetJobDetails]);

  useEffect(() => {
    if (
      callingGetJobDetails.hasFetched() &&
      callingGetJobDetails.hasErrors() === false &&
      callingGetJobDetails.data().data
    ) {
      setJobData(callingGetJobDetails.data().data?.job || {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingGetJobDetails.isFetching()]);

  useEffect(() => {
    if (
      callingUpdateFavouriteJobsAPI.hasFetched() &&
      callingUpdateFavouriteJobsAPI.hasErrors() === false &&
      callingUpdateFavouriteJobsAPI.data().message
    ) {
      refreshSavedJobs();
      toastify("success", callingUpdateFavouriteJobsAPI.data().message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingUpdateFavouriteJobsAPI.isFetching()]);

  return (
    <div className={cx("job")}>
      <div className={cx("job__back")}>
        <ButtonCustom onClick={() => navigate(routes.JOBS.SEARCH)}>
          <img src={BackArrow} alt="Back arrow" />
          Back to Jobs
        </ButtonCustom>
      </div>
      <div className={cx("job__details")}>
        {callingGetJobDetails.isFetching() ? (
          <ShimmerPostDetails card cta variant="SIMPLE" />
        ) : !callingGetJobDetails.isFetching() && jobData?.id ? (
          <>
            <div className={cx("job__details__title")}>
              <p className={cx("job__details__title__heading")}>
                {jobData?.job_title}
              </p>
              <div className={cx("job__details__title__save-job")}>
                <ButtonCustom
                  onClick={handleSaveUnsaveJob}
                  isDisabled={callingUpdateFavouriteJobsAPI.isFetching()}
                >
                  <div className={cx("job__details__title__save-job__content")}>
                    <div
                      className={cx(
                        "job__details__title__save-job__content__image"
                      )}
                    >
                      {favJobKeyList.includes(jobData?.job_key) ? (
                        <StarIcon
                          onClick={handleSaveUnsaveJob}
                          aria-label={`Remove ${jobData.job_title} from saved jobs`}
                          variant="filled"
                        />
                      ) : (
                        <StarIcon
                          onClick={handleSaveUnsaveJob}
                          aria-label={`Add ${jobData.job_title} to saved jobs`}
                        />
                      )}
                    </div>
                    <p
                      className={cx(
                        "job__details__title__save-job__content__text"
                      )}
                    >
                      Save job
                    </p>
                  </div>
                </ButtonCustom>
              </div>
            </div>
            <div className={cx("job__details__middle")}>
              <div className={cx("job__details__middle__left")}>
                <p className={cx("job__details__middle__left__company")}>
                  {jobData?.company}
                </p>
                <div className={cx("job__details__middle__left__details")}>
                  {parseEmploymentType(
                    jobData.ai_employment_type || jobData.employment_type
                  )?.length > 0 && (
                    <p
                      className={cx(
                        "job__details__middle__left__details__line"
                      )}
                    >
                      <span
                        className={cx(
                          "job__details__middle__left__details__line__header"
                        )}
                      >
                        Position
                      </span>
                      <span
                        className={cx(
                          "job__details__middle__left__details__line__data"
                        )}
                      >
                        {capitalizeFirstLetter(
                          parseEmploymentType(
                            jobData.ai_employment_type ||
                              jobData.employment_type
                          )
                        )}
                      </span>
                    </p>
                  )}
                  {parseEmploymentExperienceLevel(
                    jobData.ai_experience || jobData.experience
                  )?.length > 0 && (
                    <p
                      className={cx(
                        "job__details__middle__left__details__line"
                      )}
                    >
                      <span
                        className={cx(
                          "job__details__middle__left__details__line__header"
                        )}
                      >
                        Experience
                      </span>
                      <span
                        className={cx(
                          "job__details__middle__left__details__line__data"
                        )}
                      >
                        {parseEmploymentExperienceLevel(
                          jobData.ai_experience || jobData.experience
                        )}
                      </span>
                    </p>
                  )}
                  {parseDegree(jobData?.ai_degree || jobData?.degree)?.length >
                    0 && (
                    <p
                      className={cx(
                        "job__details__middle__left__details__line"
                      )}
                    >
                      <span
                        className={cx(
                          "job__details__middle__left__details__line__header"
                        )}
                      >
                        Degree
                      </span>
                      <span
                        className={cx(
                          "job__details__middle__left__details__line__data"
                        )}
                      >
                        {capitalizeFirstLetter(
                          parseDegree(jobData?.ai_degree || jobData?.degree)
                            ?.length > 0
                        )}
                      </span>
                    </p>
                  )}
                  {jobData?.formatted_location_full?.length > 0 && (
                    <p
                      className={cx(
                        "job__details__middle__left__details__line"
                      )}
                    >
                      <span
                        className={cx(
                          "job__details__middle__left__details__line__header"
                        )}
                      >
                        Location
                      </span>
                      <span
                        className={cx(
                          "job__details__middle__left__details__line__data"
                        )}
                      >
                        {jobData?.formatted_location_full}
                      </span>
                    </p>
                  )}
                  {parseWorkType(jobData?.ai_work_type || jobData?.work_type)
                    .length > 0 && (
                    <p
                      className={cx(
                        "job__details__middle__left__details__line"
                      )}
                    >
                      <span
                        className={cx(
                          "job__details__middle__left__details__line__header"
                        )}
                      >
                        Remote
                      </span>
                      <span
                        className={cx(
                          "job__details__middle__left__details__line__data"
                        )}
                      >
                        {capitalizeFirstLetter(
                          parseWorkType(
                            jobData?.ai_work_type || jobData?.work_type
                          )
                        )}
                      </span>
                    </p>
                  )}
                </div>
              </div>
              <div className={cx("job__details__middle__right")}>
                <ButtonCustom
                  width={156}
                  height={50}
                  onClick={() =>
                    jobData?.url && window.open(jobData.url, "_blank")
                  }
                >
                  Apply to job
                </ButtonCustom>
              </div>
            </div>
            {jobData?.country === "us" &&
            users?.student_view_preference ===
              STUDENT_VIEW_PREFERENCES_OPTIONS.INTERNATIONAL &&
            jobData.no_of_petitions > 0 ? (
              <div className={cx("job__details__visa")}>
                <p className={cx("job__details__visa__heading")}>
                  U.S. Visa Insights
                </p>
                <div className={cx("job__details__visa__body")}>
                  <p className={cx("job__details__visa__body__header")}>
                    2024 Petitions
                  </p>
                  <p
                    className={cx("job__details__visa__body__data")}
                    onClick={openCompany}
                  >
                    {numberWithCommas(jobData.no_of_petitions || 0)}
                  </p>
                  {jobData?.sponsored_last_year && (
                    <div className={cx("job__details__visa__body__tag")}>
                      <span>Sponsored last year</span>
                    </div>
                  )}
                </div>
              </div>
            ) : null}
            {jobData.ai_summary && (
              <div className={cx("job__details__ai-summary")}>
                <div className={cx("job__details__ai-summary__header")}>
                  <img src={InterstrideAiLogo} alt="Ai" />
                  <p>Job Summary</p>
                </div>
                <p className={cx("job__details__ai-summary__body")}>
                  {jobData.ai_summary}
                </p>
              </div>
            )}
            {jobData.description && (
              <div className={cx("job__details__summary")}>
                <p className={cx("job__details__summary__header")}>
                  Job Details
                </p>
                <p
                  className={cx("job__details__ai-summary__body")}
                  dangerouslySetInnerHTML={{ __html: jobData.description }}
                />
              </div>
            )}
            <div className={cx("job__details__footer")}>
              {(jobData?.formatted_relative_time || jobData?.publish_date) && (
                <p className={cx("job__details__footer__posted")}>
                  Posted{" "}
                  {jobData?.formatted_relative_time
                    ? jobData?.formatted_relative_time
                    : jobData?.publish_date
                    ? renderTimestamp(jobData?.publish_date)
                    : "N/A"}
                </p>
              )}
              <ButtonCustom
                width={156}
                height={50}
                onClick={() =>
                  jobData?.url && window.open(jobData.url, "_blank")
                }
              >
                Apply to job
              </ButtonCustom>
            </div>
          </>
        ) : !callingGetJobDetails.isFetching() ? (
          <EmptyListWithImage title={emptyCardTitle} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
