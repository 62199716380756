import React from "react";

import AmbassadorInviteStyles from "./AmbassadorInviteStyles";
import AmbassadorIllustration from "../../../../assets/AmbassadorIllustration.svg";
import Checkmark from "../../../../assets/svg/Checkmark.svg";

const benefitsList = [
  "Grow your leadership and communication skills",
  "Support, inspire, and connect with international prospective students and applicants",
  "Gain valuable volunteer experience",
  "Access Interstride's career portal for job and internship, networking, and immigration guidance",
];

const AmbassadorInvite = () => {
  return (
    <AmbassadorInviteStyles>
      <img src={AmbassadorIllustration} />
      <h1>Become a Student or Alumni Ambassador</h1>
      <div className="middle-content">
        <div className="title">
          This is your chance to represent your university and make an impact.
          As an international student or alum, you know firsthand what rich
          experiences and opportunities live at the university. By becoming an
          ambassador, you get to:
        </div>
        <div className="benefits-wrapper">
          {benefitsList.map((item, index) => (
            <div className="benefit-item" key={`benefit-${index}`}>
              <div className="benefit-item-image">
                <img src={Checkmark} />
              </div>
              {item}
            </div>
          ))}
        </div>
      </div>
      <h2>Join your university's ambassador program today.</h2>
    </AmbassadorInviteStyles>
  );
};

export default AmbassadorInvite;
